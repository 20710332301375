import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy, ViewChild} from '@angular/core';
import {FormService} from '../../../form.service';
import {AppService} from '../../../../app.service';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {Parameters} from "../../../../parameters";
import {StorageMap} from "@ngx-pwa/local-storage";
import {MatMenuTrigger} from "@angular/material/menu";
import {ResponseService} from '../../../responses/data/response.service';


@Component({
  selector: 'app-choice-preview-controls',
  templateUrl: './choice-preview-controls.component.html',
  styleUrls: ['./choice-preview-controls.component.scss']
})
export class ChoicePreviewControlsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingSelectedOption: any;
  intervalAudio: any;
  incomingResults: any;
  data: any;
  questions: any;
  form: any;
  label: any;
  incomingQuestion: any;
  incomingInvitee: any;
  incomingOption: any;
  incomingItem: any;
  incomingResult: any;
  incomingForm: any;
  incomingFormSettings: any;
  audio: any;
  isIntervalCleaned: any = false;
  incomingQuestionSettings: any;
  outgoingRequest: any;
  strings: any;
  constructor(
      public service: AppService,
      public broadcastChannel: BroadcastChannelService,
      private responseService: ResponseService,
      private storage: StorageMap,
      public formService: FormService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingInvitee = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingForm = new Parameters();
    this.strings = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnInit(): void {
  }

  ngStart(){
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.strings.setAttributes(this.service.app.strings.params);
      this.incomingInvitee.setAttributes({});
      this.incomingInvitee.setAttendeeName(this.service.upperStartingCharacter(this.strings.getGuest(), true));
      this.incomingForm.setAttributes(this.formService.getSelected());
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingFormSettings.setUnknown('lfd', this.incomingFormSettings.getAttributes().hasOwnProperty('lfd') ? this.incomingFormSettings.getUnknown('lfd') : 0);
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setResult(
          this.incomingQuestion.getAttributes().hasOwnProperty('result') &&
          !this.service.isNull(this.incomingQuestion.getResult()) ?
          Object.assign({}, this.incomingQuestion.getResult()) : {});
      this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
      this.incomingQuestionSettings.setIsMenu(this.incomingQuestionSettings.getAttributes().hasOwnProperty('is_menu') ? this.incomingQuestionSettings.getIsMenu() : 0);
      if(parseFloat(this.incomingQuestionSettings.getIsMenu()) === 1)
          this.broadcastChannel.emitNavChangeEvent({action : 'nextButtonHidden'});
      // if(parseFloat(this.incomingForm.getCategoryId()) === 4) {
      //     if(parseFloat(this.incomingQuestion.getIndex()) === 0 && !this.service.isNull(this.incomingQuestion.getData())){
      //         this.incomingQuestion.getOptions().map((option: any) => {
      //             this.incomingOption.setAttributes(Object.assign({}, option));
      //             if(JSON.stringify(this.incomingQuestion.getResult()).includes(this.incomingOption.getCntrlNum())){
      //                 this.radioChange(null, this.incomingOption.getAttributes());
      //             }
      //         });
      //     }this.incomingForm.getControls().map((question: any) => {
      //         this.incomingItem.setAttributes(question);
      //         if(!this.service.isNull(this.incomingItem.getData()) && this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeeName()))
      //             this.incomingInvitee.setAttendeeName(this.incomingItem.getData());
      //     }); this.incomingQuestion.setLabel(
      //         this.incomingQuestion.getLabel()
      //             .replaceAll('*' + this.strings.getName(), this.incomingInvitee.getAttendeeName()));
      // }
      this.incomingForm.getControls().map((question: any) => {
          this.incomingItem.setAttributes(question);
          if(!this.service.isNull(this.incomingItem.getData()) && this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeeName()))
              this.incomingInvitee.setAttendeeName(this.incomingItem.getData());
      });
      this.incomingQuestion.setLabel(
          this.incomingQuestion.getLabel()
              .replaceAll('*' + this.strings.getName(), this.incomingInvitee.getAttendeeName()));

      this.ngStart();
      if(this.incomingQuestion.getAttributes().hasOwnProperty('flag')){
          if(parseFloat(this.incomingQuestion.getFlag()) === 3){
              this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
              this.incomingQuestion.setFlag(0);
          }
      }
      if(parseFloat(this.incomingForm.getCategoryId()) === 5) {
          this.play(null);
      }

      setTimeout(() => {
          let matChekboxFrames = document.getElementsByClassName('mat-radio-outer-circle');
          for (let i = 0; i < matChekboxFrames.length; i++) {
              let matChekboxFrame = matChekboxFrames[i] as HTMLElement;
              // if(this.matChekboxFrme === null)
              //     this.matChekboxFrme = matChekboxFrame.style.borderColor;
              matChekboxFrame.style.borderColor = this.service.isNull(this.incomingFormSettings.getColor()) ? '#ff4081' : this.incomingFormSettings.getColor();
          }

          let matradioiInnerCircles = document.getElementsByClassName('mat-radio-inner-circle');
          for (let i = 0; i < matradioiInnerCircles.length; i++) {
              let matradioiInnerCircle = matradioiInnerCircles[i] as HTMLElement;
              matradioiInnerCircle.style.backgroundColor = this.service.isNull(this.incomingFormSettings.getColor()) ? '#ff4081' : this.incomingFormSettings.getColor();
          }
      });
  }


  play(result: any){
      this.intervalAudio = setInterval(() => {
          this.playSoundSelectSwahiliVersion(result ,() => {});
      }, this.service.timeout() * 10);
  }

  voiceSpeek(speechText: any, callback: any){
      var params: any = {
          input: {
              text: speechText,
          },
          audioConfig: {
              audioEncoding: 'MP3',
              pitch: 0,
              speakingRate: 0.8
          },
          voice: {
              languageCode: 'en-US',
              name :  "en-US-News-L" ,
              ssmlGender: "FEMALE"
          }
      };

//         LINEAR16
      params = JSON.stringify(params);
      var request: XMLHttpRequest = new XMLHttpRequest();
      request.open('POST', 'https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=' + this.service.textSpeechApi, true);
      request.setRequestHeader('Content-type', 'application/json');
      request.responseType = 'json';
      request.onload = () =>{
          this.incomingResult.setAttributes(request.response);
          this.incomingResult.setUnknown('audioContent', 'data:audio/x-wav;base64,' + this.incomingResult.getUnknown('audioContent'));
          let blob: any = this.service.dataURItoBlob(this.incomingResult.getUnknown('audioContent'));
          const arrayBuffer: any = Uint8Array.from(this.incomingResult.getUnknown('audioContent')).buffer;
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setSrc(null);
          this.outgoingRequest.setFlag(0);
          this.outgoingRequest.setCaption('');
          this.outgoingRequest.setOrderBy('');
          this.outgoingRequest.setStatus(1);
          this.outgoingRequest.setUrl('');
          this.outgoingRequest.setPath('');
          this.outgoingRequest.setAvatar(blob);
          this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
              this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
                  this.incomingResult.setAttributes(result);
                  const sound: any = new Audio('../assets/audio/attention.mp3');
                  sound.play();
                  sound.onended = () =>{
                      this.service.playSoundByUrl(this.incomingResult.getSrc(),() =>{
                          callback(this.incomingResult.getSrc());
                      });
                  };
              }, (error: any) => {
                  callback(null);
              });

      };
      request.onerror = (e: any) => {
          callback(null);
      };
      request.send(params);
  }

  ngOnDestroy(): void {
   if(parseFloat(this.incomingForm.getCategoryId()) === 5){
       this.audio.pause();
       this.audio.currentTime = 0;
       clearInterval(this.intervalAudio);
   }
  }



  isChecked(element: any, optional: any){
    let result: any = false;
    this.incomingQuestion.setAttributes(this.question);
    if(!this.service.isNull(this.incomingQuestion.getUnknown('data')) &&
        !this.service.isNull(optional.label) &&
        this.incomingQuestion.getUnknown('data').toString() === optional.label.toString())
        result = true;
    return result;
  }

  playSoundSelectSwahiliVersion(textSpeechUrl: any , callback: any){
     this.responseService.film.pause();
     this.audio = new Audio('../assets/audio/selectSwahiliVersion.mp3');
     this.audio.play();
     this.audio.onended = () => {
         this.responseService.film.play();
         callback(null);
     };
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    if(!this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum'))
    this.contextMenu.closeMenu();
  }

  removeOption(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum')){
        this.formService.newOptional = this.incomingSelectedOption.getAttributes();
        this.incomingQuestion.getOptions().splice(this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingSelectedOption.getCntrlNum()), 1);
        this.formService.removeOther();
        if(!this.service.isNull(this.incomingQuestion.getData())){
          if(this.incomingQuestion.getData().toString() === this.incomingSelectedOption.getLabel().toString())
          this.incomingQuestion.setData('');
        }
      }else this.contextMenu.closeMenu();
    });
  }

  handleUpdate(option: any){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingSelectedOption.getAttributes().hasOwnProperty('questionCntrlNum')){
        this.label = prompt('', this.incomingSelectedOption.getLabel());
        if (this.label !== null) {
          this.incomingSelectedOption.attributes = Object.assign(this.incomingSelectedOption.attributes, {label: this.label});
          this.formService.newOptional = this.incomingSelectedOption.getAttributes();
          this.formService.updateOther(this.label);
          if(!this.service.isNull(this.incomingQuestion.getData())){
            if(this.incomingQuestion.getData().toString() === this.incomingSelectedOption.getLabel().toString())
              this.incomingQuestion.setData(this.incomingSelectedOption.getLabel());
          }
        }
      }else this.contextMenu.closeMenu();
    });
  }

  addOptional(){
    this.incomingQuestion.setAttributes(this.question);
    this.label = prompt('', '');
    if (this.label !== null) {
      this.formService.addOptional(this.question.options, this.label);
      this.formService.newOptional = Object.assign(this.formService.newOptional, {questionCntrlNum: this.incomingQuestion.getCntrlNum()});
      this.formService.addOther();
    }
  }

  addOther(){this.storage.set('others', [this.formService.newOptional]).subscribe(() => {});}

  radioChange(event: any, optional){
     this.incomingItem.setAttributes(optional);
      this.question.data = optional.label;
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getOptions().map((option: any) => {
        this.incomingOption.setAttributes(option);
        if(this.incomingOption.getLabel().toString() === this.incomingQuestion.getData()){
        }else this.formService.hideOptionalQuestions(this.incomingOption.getAttributes());
      });

      this.incomingOption.setAttributes(optional);
      this.incomingResults.setAttributes({});
      this.incomingResult.setAttributes({});
      this.incomingResult.setLabel(this.incomingOption.getLabel());
      this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
      this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
      this.incomingQuestion.setResult(Object.assign({}, this.incomingResults.getAttributes()));
      this.broadcastChannel.emitNavChangeEvent({action: 'next',  optionalId: optional.id, optionalCntrlNum: optional.cntrl_num, cntrlNum: optional.cntrl_num});
  }

  isnull(radionButton: any) {
    if(this.service.isNull(this.question.data)) {
     radionButton.checked = false;
    }
  }

  hideAddOthers(){
    this.incomingQuestion.setAttributes(this.question);
    return this.service.parseFloat(this.incomingQuestion.getAddOthers()) === 0;
  }

  ngAfterViewInit(): void {
    this.incomingQuestion.setAttributes(this.question);
  }




}


