import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Parameters} from "../../../parameters";
import {AppService} from "../../../app.service";
import {ResponseService} from "../../../forms/responses/data/response.service";
import {InvisibleReCaptchaComponent} from "ngx-captcha";
import {FormService} from "../../../forms/form.service";
import {NotifierService} from "angular-notifier";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-exhibitor-registration-events',
  templateUrl: './exhibitor-registration-events.component.html',
  styleUrls: ['./exhibitor-registration-events.component.scss']
})
export class ExhibitorRegistrationEventsComponent implements OnInit, OnDestroy {
  @ViewChild('recaptchaId') recaptchaId: any;
  formValues: FormGroup = new FormGroup({});
  incomingForm: any;
  outgoingParams: any;
  strings: any;
  questions: any = [];
  incomingQuestion: any;
  outgoingResponse: any;
  siteKey = '6Lcc-W0UAAAAAAdcFGL0d47jvGH5Eemf2DqBiOwK';
  recaptcha: any = '';
  formId: any;
  constructor(public service: AppService,
              private responseService: ResponseService,
              public formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private notifier: NotifierService,
              public formService: FormService) {
    this.incomingForm = new Parameters();
    this.incomingQuestion = new Parameters();
    this.outgoingResponse = new Parameters();
    this.outgoingParams = new Parameters();
    this.strings = new Parameters();
    this.recaptchaId = InvisibleReCaptchaComponent;
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.outgoingParams.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingQuestion.setAttributes({});
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.overflow = 'visible';
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.formId = this.activatedRoute.snapshot.params.formId;
    this.formId = this.service.decrypt(this.formId);
    if(this.incomingForm.hasAttribute(this.strings.getId())){
      // this.formValues = this.formBuilder.group({});
      this.incomingForm.getControls()[0].options[2].questions.map((question: any) => {
        this.incomingQuestion.setAttributes(Object.assign({}, question));
        this.incomingQuestion.setData('');
        this.incomingQuestion.setResult({});
        this.questions.push(this.incomingQuestion.getAttributes());
      });
    }else {
      let url: any = window.location.href.split('?');
      url = url.length === 2 ? url[1] : '';
      this.router.navigateByUrl(
          this.strings.getSlash() +
          this.strings.getApp() +
          this.strings.getSlash() +
          this.strings.getEvents() +
          this.strings.getSlash() + this.service.encrypt(this.formId) + this.strings.getSlash() +
          this.strings.getAnnoucement() + '?' + url);
    }
  }

  ngOnDestroy(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgb(16,30,81)';
  }

  handleReset(){
  }

  handleLoad(){}

  handleSuccess(event: any){
    this.recaptcha = event;
  }

  handleExpire(){
    this.recaptchaId.reloadCaptcha();
    this.recaptcha = '';
  }

  onChecked(question: any, option: any, HTMLCheck: any){
    let incomingQuestion: any = new Parameters();
    let incomingOption: any = new Parameters();
    let incomingResult: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingOption.setAttributes(option);
    incomingResult.setAttributes(incomingQuestion.getResult());
    if(HTMLCheck.checked){
      incomingResult.setUnknown(incomingOption.getCntrlNum(), {label : incomingOption.getLabel()});
    }else { if (incomingResult.getAttributes().hasOwnProperty(incomingOption.getCntrlNum()))
      delete incomingResult.getAttributes()[incomingOption.getCntrlNum()];
    }
    incomingQuestion.setData('');
    for (const key in incomingResult.getAttributes()) {
      let incomingLabel: any = new Parameters();
      incomingLabel.setAttributes(incomingResult.getAttributes()[key]);
      incomingQuestion.setData(incomingQuestion.getData() + ':' +
          incomingLabel.getAttributes()[this.strings.getLabel()]);
    }
  }

  onInputTextChange(question: any, $event: any){
    let incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setData($event.target.value);
  }

  validate(call: any){
    let incomingQuestionValidate: any = new Parameters();
    incomingQuestionValidate.setAttributes({});
    this.questions.map((question: any) => {
      let incomingResponseResult = new Parameters();
      let incomingQuestionSettings = new Parameters();
      incomingResponseResult.setAttributes(question);
      incomingQuestionSettings.setAttributes(incomingResponseResult.getSettings());
      incomingResponseResult.setRequired(
          incomingResponseResult.hasAttribute(this.strings.getRequired()) &&
          !this.service.isNull(incomingResponseResult.getRequired()) ? incomingResponseResult.getRequired() : 0);
      if(parseFloat(incomingResponseResult.getRequired()) !== 0 && this.service.isNull(incomingResponseResult.getData())){
        if(!incomingQuestionValidate.hasAttribute(this.strings.getId()))
          incomingQuestionValidate.setAttributes(incomingResponseResult.getAttributes());
      }
    });
    if(incomingQuestionValidate.hasAttribute(this.strings.getId())) {
      this.notifier.notify(this.strings.getSuccess(), incomingQuestionValidate.getLabel() + ' ' + this.strings.getRequired());
      let elementId: any = document.getElementById(incomingQuestionValidate.getIndex());
      elementId.scrollIntoView({behavior:"smooth"});
    }call(incomingQuestionValidate.hasAttribute(this.strings.getId()) ? 1 : 0);
  }

  ngSubmit(formValues: any){
    this.validate((result: any) => {
      if(parseFloat(result) === 0){
        if(this.service.isNull(this.recaptcha)){
          this.notifier.notify(this.strings.getSuccess(),
              this.strings.getRecaptchaValidationText()); return;
          // window.scrollTo(0, this.service.getHeight());
        }this.responseService.setPost(this.incomingForm.getAttributes(), (response) => {
          this.outgoingResponse.setAttributes(response);
          let questions: any = [];
          this.questions.map((question: any) => {
            let $incomingQuestion: any = new Parameters();
            $incomingQuestion.setAttributes(Object.assign({}, question));
            $incomingQuestion.setResult($incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull($incomingQuestion.getResult()) ?
                JSON.stringify($incomingQuestion.getResult()) : '');
            $incomingQuestion.getAttributes()[this.strings.getFormControlId()] =
                $incomingQuestion.getAttributes().control.id;
            $incomingQuestion.attributes = Object.assign($incomingQuestion.getAttributes(), {
              data: this.service.isNull($incomingQuestion.getAttributes().data) ? '' :
                  $incomingQuestion.getAttributes().data});
            delete $incomingQuestion.getAttributes().options;
            questions.push($incomingQuestion.getAttributes());
          });
          this.outgoingResponse.setControls(questions);
          let posts: any = [];
          posts.push(this.outgoingResponse.getAttributes());
          this.router.navigateByUrl(this.strings.getWait());
          this.service.httpService(
              'POST',
              this.service.app.routes.responses.post,
              {posts: JSON.stringify(posts)},
              {ignoreLoadingBar : 'true', notify: 'false'},
              (result: any) => {
                let url: any = window.location.href.split('?');
                url = url.length === 2 ? url[1] : '';
                this.router.navigateByUrl(
                    this.strings.getSlash() +
                    this.strings.getApp() +
                    this.strings.getSlash() +
                    this.strings.getEvents() +
                    this.strings.getSlash() + this.service.encrypt(this.formId) + this.strings.getSlash() +
                    this.strings.getAnnoucement() + '?' + url);
                setTimeout(() => {
                  this.notifier.notify(this.strings.getSuccess(),
                      this.strings.getWillTouch());
                }, this.service.timeout());
              }, (error: any) => {
              });
        });
      }
    });
  }

}
