<div [ngStyle]="{'z-index': '9999x','position': 'relative','top':'5%','right':'5%'}" *ngIf="selectedTabIndex === 3">
    <app-label-create-control [question]="question"></app-label-create-control>
    <div [ngStyle]="{'margin-top':'50px'}">
        <ul class="list-inline">
            <li (click)="selectedTabIndex = 1; openCamera()"><button mat-fab class="defaultColor2"><mat-icon>photo_camera</mat-icon></button></li>
<!--        <li (click)="image.click()"><button mat-mini-fab class="defaultColor2"><mat-icon>open_in_browser</mat-icon></button></li>-->
        </ul>
    </div>
    <div *ngIf="incomingQuestion.getData() && !service.isNull(incomingQuestion.getData()) && selectedTabIndex === 3">
        <img [src]="incomingQuestion.getData()"
             ngClass="mdl-shadow--3dp img-circle"
             [ngStyle]="{'width':'200px','height':'200px'}"/>
    </div>
</div>
<ng-container *ngIf="selectedTabIndex !== 3">
    <ng-container *ngIf="!isUploading">
        <div [ngStyle]="{'position': 'fixed','top':'-108px','right':'0px'}">
            <div [ngStyle]="{'width':'100%','height':(this.service.getHeight())+'px','overflow-y':'hidden'}">
                <mat-tab-group [(selectedIndex)]="selectedTabIndex"
                               (click)="onClickTabGroup()"
                               (selectedTabChange)="onSelectedTabChange($event)"
                               [ngStyle]="{'overflow': 'hidden'}">
                    <mat-tab style="overflow: hidden">
                        <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height':(this.service.getHeight()+9)+'px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">
                            <carousel [cellsToScroll]="1"
                                      [cellsToShow]="1"
                                      [height]="this.service.getHeight()"
                                      [autoplay]="true"
                                      [pauseOnHover]="true"
                                      [arrows]="false"
                                      [dots]="false"
                                      [loop]="true"
                                      *ngIf="incomingForm.getResponses().data.length && selectedTabIndex === 0 && showCarousel">
                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">
                                    <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">
                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">
                                            <div class="carousel-cell" *ngIf="incrementalResponseResult === 0" style="overflow: hidden">
                                                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                                                     (error)="onErrorHandle($event)"
                                                     src="{{responseResult.data}}"
                                                     #img />
<!--                                                <div [ngStyle]="{'position':'fixed','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px','background':'url('+'assets/images/parrten-bg.png'+') repeat'}">-->
<!--                                                    <div class="vertical-center"-->
<!--                                                         [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}">-->
<!--                                                        <center>-->
<!--                                                            <img src="{{this.service.logo}}"-->
<!--                                                                 class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--                                                                 [ngStyle]="{'width': '60px','height':'60px','margin-bottom':'20px'}"-->
<!--                                                                 *ngIf="!this.service.isNull(this.service.logo)"/>-->
<!--                                                            <h2 class="sawasdee18 txtWhite">-->
<!--                                                                welcome to kisiga wedding,live photo wall-->
<!--                                                            </h2>-->
<!--                                                            <div  class="sawasdee26 lineHeight23 txtWhite">-->
<!--                                                                let got everything saved as memories, share your wedding photos or view existing ones-->
<!--                                                            </div>-->
<!--                                                            <h4 class="sawasdee20 txtWhite">-->
<!--                                                                every upload is saved automatically in a kisiga wedding album and appears on TV screen-->
<!--                                                            </h4>-->
<!--                                                            <div class="sawasdee14"-->
<!--                                                                 [ngStyle]="{'margin-top': '10px'}">-->
<!--                                                                <center>-->
<!--                                                                    &lt;!&ndash;                                                                <div class="banner-menu">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                    <ul>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            (click)="image.click()">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">upload image</span>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">upload video</span>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            (click)="selectedTabIndex = 1">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">open camera</span>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        <li [ngStyle]="{'cursor':'pointer'}"&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            (click)="selectedTabIndex = 3">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                            <span class="mitra26Wht">view album</span>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                        </li>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                    </ul>&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--                                                                    <div class="btn btn-lg btn-default sawasdee14"-->
<!--                                                                         (click)="image.click()">-->
<!--                                                                        upload photo-->
<!--                                                                    </div>-->
<!--                                                                    &lt;!&ndash;                                                                <div class="btn ovalButton">&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                    upload video&ndash;&gt;-->
<!--                                                                    &lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--                                                                    <div class="btn btn-lg btn-default sawasdee14"-->
<!--                                                                         (click)="selectedTabIndex = 1">-->
<!--                                                                        open camera-->
<!--                                                                    </div>-->
<!--                                                                    <div class="btn btn-lg btn-default sawasdee14"-->
<!--                                                                         (click)="selectedTabIndex = 3">-->
<!--                                                                        view album-->
<!--                                                                        &lt;!&ndash;                                                                {{service.upperStartingCharacter(incomingForm.getName(),true).toLowerCase()}}&ndash;&gt;-->
<!--                                                                    </div>-->
<!--                                                                </center>-->
<!--                                                            </div>-->

<!--                                                        </center>-->
<!--                                                    </div>-->
<!--                                                </div>-->
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </carousel>
                        </div>
                    </mat-tab>
                    <mat-tab style="overflow: hidden" [ngStyle]="{'background-color':'#000'}">
                        <div  [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px','height': this.service.getHeight()+'px'}">
                            <webcam [ngStyle]="{'background-color':'#000'}"
                                    (imageCapture)="handleImage($event)"
                                    [height]="service.getHeight()"
                                    [width]="service.getWidth()"
                                    [trigger]="triggerObservable"
                                    [mirrorImage]="'never'"
                                    [allowCameraSwitch]="allowCameraSwitch"
                                    [switchCamera]="nextWebcamObservable"
                                    [videoOptions]="videoOptions"
                                    [captureImageData]="true"
                                    [imageType]="'image/jpeg'"
                                    (imageClick)="afterimageClick()"
                                    [imageQuality]="1"
                                    (cameraSwitched)="cameraWasSwitched($event)"
                                    (initError)="handleInitError($event)"
                                    *ngIf="showWebcam">
                            </webcam>
                            <div [ngStyle]="{'background-color':'#000','z-index':'9999x','position':'fixed','bottom':'0px','left':'0px','width':service.getWidth()+'px'}">
                                <center>
                                    <ul class="list-inline">
                                        <li class="cursor">
                                            <i (click)="triggerSnapshot()"
                                               class="material-icons cursor"
                                               [ngStyle]="{'color':'#fff','font-size':'62px'}">motion_photos_on</i>
                                        </li>
                                        <li (click)="showNextWebcam(true)">
                                            <i class="material-icons cursor"
                                               [ngStyle]="{'color':'#fff'}">flip_camera_android</i>
                                        </li>
                                    </ul>
                                </center>
                            </div>
                            <div [ngStyle]="{'z-index':'9999999999999999','position':'fixed','top':'5px','right':'20px'}">
                                <ul class="list-inline">
                                    <li class="cursor" (click)="selectedTabIndex = 3;afterClose()">
                                        <mat-icon class="cursor" [ngStyle]="{'color':'#fff'}">close</mat-icon>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab style="overflow: hidden">
                        <!--            <ng-template mat-tab-label>-->
                        <!--                <i class="material-icons">tune</i>-->
                        <!--            </ng-template>-->
                        <div>
                            <div [ngStyle]="{'background-color': '#000','position': 'fixed','top': '0px','left': '0px','width': service.getWidth()+'px','height': service.getHeight()+'px'}">
                                <img  class="mdl-shadow--2dp"
                                      src="{{this.picUrl}}" [ngStyle]="{'width':'100%', 'height':'auto'}"/>
                            </div>

                            <div [ngStyle]="{'background-color':'rgba(30, 18, 18, 0.67)','width': service.getWidth()+'px','position': 'fixed','right': '0px','top': '40%','padding-top': '0px','padding-bottom': '0px'}">
                                <center>
                                    <!--                                <section class="post-section blog-post"-->
                                    <!--                                         [ngStyle]="{'background-color': 'transparent'}">-->
                                    <!--                                    <div class="container">-->
                                    <!--                                        <div class="row">-->
                                    <!--                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
                                    <!--                                                 (click)="sendCapturedCameraImg(drawer)">-->
                                    <!--                                                <div class="post-box">-->
                                    <!--                                                    <div class="text-box sawasdee16BoldX4">-->
                                    <!--                                                        <h4>-->
                                    <!--                                                            <a>Upload your image</a>-->
                                    <!--                                                        </h4>-->
                                    <!--                                                        <p></p>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"-->
                                    <!--                                                 (click)="cancelCaptureCameraImg()">-->
                                    <!--                                                <div class="post-box">-->
                                    <!--                                                    <div class="text-box sawasdee16BoldX4">-->
                                    <!--                                                        <h4>-->
                                    <!--                                                            <a>Cancel</a>-->
                                    <!--                                                        </h4>-->
                                    <!--                                                        <p></p>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </div>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <!--                                </section>-->
                                    <table [ngStyle]="{'width':'100%'}">
                                        <tr [ngStyle]="{'width':'100%'}">
                                            <td class="mitra18" [ngStyle]="{'width':'100%'}">
                                                <center>
                                                    <div class="btn ovalButtonBlackground sawasdee cursor"
                                                         (click)="sendCapturedCameraImg(drawer);afterClose()">
                                                        <mat-icon class="material-icons">done</mat-icon>
                                                    </div>
                                                    <div class="btn ovalButtonBlackground cursor"
                                                         (click)="cancelCaptureCameraImg();afterClose()"
                                                         [ngStyle]="{'width':'100%'}">
                                                        <mat-icon class="material-icons">close</mat-icon>
                                                    </div>
                                                </center>
                                            </td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab style="overflow: hidden">
                        <!--            <ng-template mat-tab-label>-->
                        <!--                <i class="material-icons">tune</i>-->
                        <!--            </ng-template>-->
                        <div [ngStyle]="{'position': 'fixed','top':'-97px','right':'0px'}">
                            <div [ngStyle]="{'width':'100%','height': (this.service.getHeight()+97)+'px','overflow-y':'hidden'}">
                                <mat-tab-group [(selectedIndex)]="selectedTabIndex2">
                                    <mat-tab style="overflow: hidden">
                                        <div [ngStyle]="{'background-color': '#000','width': this.service.getWidth()+'px'}"
                                             *ngIf="incomingForm.getResponses().data.length !== 0">
                                            <carousel [cellsToScroll]="1"
                                                      [cellsToShow]="1"
                                                      [height]="this.service.getHeight()"
                                                      [autoplay]="false"
                                                      [arrows]="true"
                                                      [dots]="false"
                                                      [loop]="true">
                                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">
                                                    <ng-container *ngFor="let responseResult of response.datas; let incrementalResponseResult =index">
                                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">
                                                            <div class="carousel-cell" *ngIf="incrementalResponseResult === 0" style="overflow: hidden">
                                                                <img [ngStyle]="{'width': '100%', 'height': this.service.getHeight()+'px', 'display':'block', 'object-fit': 'cover'}"
                                                                     src="{{responseResult.data}}" #img />
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </carousel>
                                        </div>
                                    </mat-tab>
                                    <mat-tab style="overflow: hidden">
<!--                                        <ng-template mat-tab-label>-->
<!--                                            <img src="assets/images/option-icon.png"-->
<!--                                                 [ngStyle]="{'color':'#fff'}" />-->
<!--                                        </ng-template>-->
<!--                                        <div [ngStyle]="{'background-color': '#000','width':this.service.getWidth()+'px'}">-->
<!--                                            <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">-->
<!--                                                <ng-container *ngFor="let response of (incomingForm.getResponses().data);let incrementalResponse=index">-->
<!--                                                    <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">-->
<!--                                                        <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">-->
<!--                                                            <mat-grid-tile>-->
<!--                                                                <div class="cursor demo-card-image mdl-card mdl-shadow&#45;&#45;2dp"-->
<!--                                                                     (contextmenu)="incomingResponseResultSelected.setAttributes(responseResult);onContextMenu($event, responseResult)"-->
<!--                                                                     style="background:url({{responseResult.data}}) center / cover"-->
<!--                                                                     (click)="incomingResponseResultSelected.setAttributes(responseResult);view(responseResult)">-->
<!--                                                                </div>-->
<!--                                                                <i class="material-icons cursor" [ngStyle]="{'z-index':'9999x9999x999','position':'absolute','top':'0px','left':'0px','color':'#000'}"-->
<!--                                                                   (click)="incomingResponseResultSelected.setAttributes(responseResult); downloadImg()">system_update_alt</i>-->
<!--&lt;!&ndash;                                                                <div class="demo-card-image mdl-card mdl-shadow&#45;&#45;2dp" style="background:url({{responseResult.data}}) center / cover">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="mdl-card__title mdl-card&#45;&#45;expand"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="mdl-card__actions" [ngStyle]="{'background': 'rgba(0, 0, 0, 0.2)','padding': '16px','height': '52px'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        <span class="demo-card-image__filename">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                            <i class="material-icons" [ngStyle]="{'color':'red'}">archive</i>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                </div>&ndash;&gt;-->
<!--                                                            </mat-grid-tile>-->
<!--                                                        </ng-container>-->
<!--                                                    </ng-container>-->
<!--                                                </ng-container>-->
<!--                                            </mat-grid-list>-->
<!--                                            <div class="cursor"-->
<!--                                                 [ngStyle]="{'z-index': '9999x999','position': 'fixed','top':'5px','right':'5px'}">-->
<!--                                                <div [ngStyle]="{'padding':'0px'}">-->
<!--                                                    <ul class="list-inline" [ngStyle]="{'color':'#fff'}">-->
<!--                                                        <li (click)="flag=1; onContextMenu($event, {})">-->
<!--                                                            <div class="mitrax"-->
<!--                                                                 [ngStyle]="{'margin-top': '0px'}">-->
<!--                                                                <center>-->
<!--                                                                    <img src="assets/images/menu-icon-2.png"-->
<!--                                                                         [ngStyle]="{'color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)'}" />-->
<!--&lt;!&ndash;                                                                    <div class="btn ovalButtonBlackground2 mitra"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         (click)="image.click()">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        upload image <i class="material-icons">north_east</i>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                            <div class="btn ovalButtonBlackground">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                                    upload video&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    &lt;!&ndash;                                                            </div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    <div class="btn ovalButtonBlackground2 mitra"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                         (click)="selectedTabIndex = 1">&ndash;&gt;-->
<!--&lt;!&ndash;                                                                        open camera <i class="material-icons">linked_camera</i>&ndash;&gt;-->
<!--&lt;!&ndash;                                                                    </div>&ndash;&gt;-->
<!--                                                                </center>-->
<!--                                                            </div>-->
<!--                                                        </li>-->
<!--                                                        &lt;!&ndash;                                                    <li (click)="selectedTabIndex2 === 1 ? selectedTabIndex2 = 0 : selectedTabIndex2 = 1;">&ndash;&gt;-->
<!--                                                        &lt;!&ndash;                                                        <img src="assets/images/option-icon-2.png"&ndash;&gt;-->
<!--                                                        &lt;!&ndash;                                                             [ngStyle]="{'color':'#fff'}" />&ndash;&gt;-->
<!--                                                        &lt;!&ndash;                                                    </li>&ndash;&gt;-->
<!--                                                    </ul>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <a  class=""-->
<!--                                                *ngIf="this.incomingForm.getResponses().data.length !== 0"-->
<!--                                                (click)="getOldImg()"-->
<!--                                                mat-mini-fab-->
<!--                                                [ngStyle]="{'position': 'fixed','z-index': '9999x999','bottom':'20px','left':'47%','background': '#fff','color':'#000'}">-->
<!--                                                <i class="fa fa-chevron-down"></i>-->
<!--                                            </a>-->
<!--                                        </div>-->
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isUploading">
        <ng-container *ngIf="!service.isNull(service.logo)">
            <img  class="logo img-circle"
                  src="{{service.logo}}"
                  alt=""/>
        </ng-container>
    </ng-container>
</ng-container>
<!--<ng-container *ngIf="service.parseFloat(this.incomingForm.getCategoryId()) !== 6">-->
<!--    <div class="trans-tab">-->
<!--        <mat-tab-group [(selectedIndex)]="selectedTabIndex"-->
<!--                       (selectedTabChange)="onSelectedTabChange($event)">-->
<!--            <mat-tab>-->
<!--                <ul class="list-inline" [ngStyle]="{'color':'#fff'}">-->
<!--                    <li>-->
<!--                        <div class=""-->
<!--                             [ngStyle]="{'margin-top': '0px'}">-->
<!--                            <center>-->
<!--                                <div class="btn ovalButtonBlackground2"-->
<!--                                     [ngStyle]="{'background-color':'transparent'}"-->
<!--                                     (click)="image.click()">-->
<!--                                    upload image-->
<!--                                </div>-->
<!--                                <div class="btn ovalButtonBlackground2"-->
<!--                                     [ngStyle]="{'background-color':'transparent'}"-->
<!--                                     (click)="selectedTabIndex = 1">-->
<!--                                    open camera-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <img src="{{incomingQuestion.getUnknown('imgSrc')}}" [ngStyle]="{'width':'400px','height':'400px'}"/>-->
<!--                                </div>-->
<!--                            </center>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </mat-tab>-->
<!--            <mat-tab>-->
<!--                xsbjsbfjf dfhdbfd-->
<!--            </mat-tab>-->
<!--        </mat-tab-group>-->
<!--    </div>-->
<!--</ng-container>-->

<input type="file"
       (change)="getImage($event)"
       [ngStyle]="{'display':'none'}"
       accept="image/*"
       #image>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <ng-container *ngIf="flag===0">
            <button mat-menu-item (click)="downloadImg()">Download</button>
        </ng-container>
        <ng-container *ngIf="flag===1">
            <button mat-menu-item (click)="image.click()">Upload photo</button>
            <button mat-menu-item (click)="selectedTabIndex = 1">Open Camera</button>
        </ng-container>
    </ng-template>
</mat-menu>

