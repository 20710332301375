import { Component, OnInit } from '@angular/core';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {Parameters} from "../../../parameters";
import {FormService} from "../../form.service";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent implements OnInit {

  constructor(private broadcastChannel: BroadcastChannelService,
              public service : AppService,
              private formService: FormService) { }

  incomingForm: any;
  incomingFormSettings: any;
  ngOnInit(): void {
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingForm.setAttributes(this.formService.selected);
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
  }

  next(){
   this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
  }

}
