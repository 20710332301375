import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {AppService} from '../app.service';
import {DialogFormsComponent} from './dialog-forms.component';
import {MatDialog} from '@angular/material/dialog';
import {ListTeamsComponent} from '../teams/list/list-teams.component';
import {NotifierService} from 'angular-notifier';
import {SettingsFormComponent} from '../settings/settings-form.component';
import {BroadcastChannelService} from '../broadcast-channel.service';
import * as XLSX from 'ts-xlsx';
import {ResponseService} from './responses/data/response.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {StorageMap} from "@ngx-pwa/local-storage";

@Injectable({
  providedIn: 'root'
})
export class FormService {
  formValues: any;
  /**
   * @param mixed Data
   */

  incomingQuestion: any;
  /**
   * @param mixed Data
   */

  isAdvert: any = false;
     /**
      * @param mixed Data
      */

  incomingRow: any;
  /**
   * @param mixed Data
   */

  customReportSelectedId: any = null;
  /**
   * @param mixed Data
   */

  incomingItem: any;
  /**
   * @param mixed Data
   */


  responseValues: any;
  /**
   * @param mixed Data
   */
  pdf: any;
  /**
   * @param mixed Data
   */
  submitButtonDisableStatus: any = false;
  /**
   * @param mixed Data
   */
  questionValues: any;
  /**
   * @param mixed Data
   */

  submit: any = false;
  /**
   * @param mixed Data
   */

  objects: any = {};
  /**
   * @param mixed Data
   */


  isOptionalClicked = false;
  /**
   * @param mixed Data
   */

  others: any = [];


  /**
   * @param mixed Data
   */

  mainGroupOptional: any = null;


  /**
   * @param mixed Data
   */

  mainGroup: any = null;


  /**
   * @param mixed Data
   */

  newQuestion: any;

  /**
   * @param mixed Data
   */

  newOptional: any;

  /**
   * @param mixed Optional
   */

  choiceQuestional: any = null;

  /**
   * @param mixed Data
   */

  groupOptional: any = null;

  /**
   * @param mixed Data
   */

  campaignId: any;

  /**
   * @param mixed Data
   */

  start: any;

  /**
   * @param mixed Data
   */

  end: any;

  /**
   * @param mixed Data
   */



  categoryId: any;

  /**
   * @param mixed Data
   *
   */

  status: any;

  /**
   * @param mixed Data
   */

  position: any = 0;

  /**
   * @param mixed Data
   */

  groupQuestions: any = [];

  /**
   * @param mixed Data
   */

  isViewOpened: any;

  /**
   * @param mixed Data
   */

  group: any = null;

  /**
   * @param mixed null
   */

  optionalFiles: any = {};

  /**
   * @param mixed null
   */

  incomingOption: any;


  /**
   * @param mixed Data
   */


  invitees: any = {data: [] , trashed: []};

  /**
   * @param mixed Data
   */

  deletedOptions: any = [];

  /**
   * @param mixed Data
   */

  formSelectedId: any = 0;

  /**
   * @param mixed formId
   */

  deletedOptionQuestions: any = [];

  /**
   * @param mixed Data
   */


  questions: any = [];

  /**
   * @param mixed Data
   */

  isOpenDialog: any = false;

  /**
   * @param mixed Data
   */


  deletedQuestions: any = [];

  /**
   * @param mixed Data
   */


  question: any = {};

  /**
   * @param mixed null
   */

  report: any;

  /**
   * @param mixed null
   */

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};

  /**
   * @param mixed null
   */


  getData(): void {
    /**
     * @param mixed null
     */
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    /**
     * @param mixed null
     */
    this.data = data;
  }

  getSelected() {
    /**
     * @param mixed null
     */
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    /**
     * @param mixed null
     */
    this.selected = selected;
  }

  files: any = {};
  /**
   * @param mixed
   */
  outgoingQuestions: any;
  /**
   * @param mixed
   */

  incomingOptionalQuestion: any;
  /**
   * @param mixed
   */

  outgoingResponse: any;
  /**
   * @param mixed
   */

   color: any = "#5A738E";
     /**
      * @param mixed
      */

   strings: any;
  /**
   * @param mixed
   */
  constructor(private notifier: NotifierService,
              private storage: StorageMap,
              private broadcastChannelService: BroadcastChannelService,
              private service: AppService,
              public dialog: MatDialog) {

    this.responseValues = new Parameters();
    /**
     * @param mixed
     */
    this.incomingItem = new Parameters();
    /**
     * @param mixed
     */

    this.outgoingResponse = new Parameters();
    /**
     * @param mixed
     */

    this.strings = new Parameters();
    /**
     * @param mixed
     */

    this.incomingOption = new Parameters();
    /**
     * @param mixed
     */

    this.incomingOptionalQuestion = new Parameters();
    /**
     * @param mixed
     */
    this.incomingRow = new Parameters();
    /**
     * @param mixed
     */

    this.incomingQuestion = new Parameters();
    /**
     * @param mixed
     */

    this.formValues = new Parameters();
    /**
     * @param mixed
     */
    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setFormId(0);

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);


  }

  positions(){
    this.questions.map((question: any, key: any) => { this.questions[key].index = key;});
  }

  addQuestion(questions: any, control: any, position: any){

    this.questionValues = new Parameters();
    /**
     * @param mixed null
     */

    this.questionValues.setAttributes({});

    /**
     * @param mixed null
     */

    this.questionValues.setControlId(control.id);

    /**
     * @param mixed null
     */

    this.questionValues.setSettings({l: null});

    /**
     * @param mixed null
     */

    this.questionValues.setUnknown('visible', 1);

    /**
     * @param mixed null
     */

    this.questionValues.setUnknown('cntrl_num', this.service.random());

    /**
     * @param mixed null
     */

    this.questionValues.setOptions([]);

    /**
     * @param mixed null
     */

    this.questionValues.setControl({id: control.id, name: 'Text'});

    /**
     * @param mixed null
     */

    this.questionValues.setPlaceholder('');

    /**
     * @param mixed null
     */

    this.questionValues.setRequired(0);

    /**
     * @param mixed null
     */

     this.questionValues.setResult({l: null});

         /**
          * @param mixed null
          */

    this.questionValues.setLabel('Untitled Question');

    /**
     * @param mixed null
     */

    this.questionValues.setIndex(position);

    /**
     * @param mixed null
     */

    this.questionValues.setName(control.name);

    /**
     * @param mixed null
     */


    this.questions.splice(parseFloat(position) + 1, 0, this.questionValues.getAttributes());


    /**
     * @param mixed null
     */

    this.positions();

    /**
     * @param mixed null
     */
    this.newQuestion = this.questionValues.getAttributes();
  }

  setItemTitle(item: any, field: any, placeholder: any){
    if(window.location.href.includes('respond'))return;
    let label: any = prompt('', this.service.isNull(item[field]) ? placeholder : item[field]);
    if (label !== null) item[field] = label;
  }


  getFormsByCampaigns(campaigns: any){
    let forms: any = {};
    forms = {data: campaigns.data.map((campaign: any) => { return campaign.forms.data; }).flat(1),
    trashed:  {data: campaigns.data.map((campaign: any) => { return campaign.forms.trashed.data; }).flat(1)}};
    return forms;
  }

  addOptional(options: any, label: any){
    this.incomingOption  = new Parameters();
    this.incomingOption.setAttributes({});
    this.incomingOption.setLabel(label);
    this.incomingOption.setQuestions([]);
    this.incomingOption.setSettings({l: null});
    this.incomingOption.setResult({l: null});
    this.incomingOption.setUnknown('cntrl_num', this.service.random());
    this.newOptional = this.incomingOption.getAttributes();
    options.push(this.incomingOption.attributes);
  }

  getQuestionByCntrlNum(cntrlNum: any){
    let outgoingQuestions: any = this.questions.filter((questional: any) => { return questional.cntrl_num.toString().includes(cntrlNum.toString());});
    return outgoingQuestions.length === 0 ? {} : outgoingQuestions[0];
  }

  setDate(responseService, dashboardService){
    if(window.location.href.toString().includes('dashboard')){
      if(responseService.reportValues.attributes.starting_at.toString().includes(dashboardService.reportValues.attributes.starting_at.toString()) &&
        responseService.reportValues.attributes.ending_at.toString().includes(dashboardService.reportValues.attributes.ending_at.toString())){}else{
        responseService.reportValues.attributes.starting_at = dashboardService.reportValues.attributes.starting_at;
        responseService.reportValues.attributes.ending_at   = dashboardService.reportValues.attributes.ending_at;
        responseService.reportValues.data = null;
      }
    }else{
        if(responseService.reportValues.attributes.starting_at.toString().includes(dashboardService.reportValues.attributes.starting_at.toString()) &&
          responseService.reportValues.attributes.ending_at.toString().includes(dashboardService.reportValues.attributes.ending_at.toString())){}else{
          dashboardService.reportValues.attributes.starting_at = responseService.reportValues.attributes.starting_at;
          dashboardService.reportValues.attributes.ending_at = responseService.reportValues.attributes.ending_at;
          dashboardService.reportValues.attributes.data = null;
        }
    }
  }

  save(response: any){
    this.outgoingResponse.setAttributes({});
    this.outgoingResponse.setResponses(JSON.stringify([response]));
    this.service.httpService( 'post',
        '/responses/pending/addrequest',
        this.outgoingResponse.getAttributes(), {
          ignoreLoadingBar: 'true',
          // notify: false
        }, (response: any) => {
        },  (error: any) => {
        });
  }

  getQuestionByQuestionsAndCntrlNum(questions: any, cntrlNum: any){
    let outgoingQuestions: any = questions.filter((questional: any) => { return questional.cntrl_num.toString().includes(cntrlNum.toString());});
    return outgoingQuestions.length === 0 ? {} : outgoingQuestions[0];
  }

  showQuestions(optional: any){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((questionOption: any) => {
        this.questions.map((question: any, l: any) => {
          if(question.cntrl_num.toString().includes(questionOption.cntrl_num.toString())){
            question.visible = 1;
            this.appendGroupSign(question);
            question.options.map((opt: any) => {
              if (question.control.id === 5) {
                if (!this.service.isNull(question.data) && question.data.toString() === opt.label.toString()) {
                  this.showQuestions(opt);
                }
              }else if(question.control.id === 18){}
              else this.showQuestions(opt);
            });
          }
        });
      });
    }
  }

  hasItemByControls(controls: any){
    this.strings.setAttributes(this.service.app.strings.params);
    let result: any = 0;
    controls.map((control: any) => {
      let incomingControl: any = new Parameters();
      let incomingControlSettings: any = new Parameters();
      incomingControl.setAttributes(control);
      incomingControlSettings.setAttributes(incomingControl.getSettings());
      if(incomingControlSettings.hasAttribute(this.strings.getItem()) && !this.service.isNull(incomingControlSettings.getItem())) result = 1;
    });return result;
  }


  countOptionalQuestions(resultOpt: any, optional: any, callback: any){
    try{
      let incomingQuestion: any = new Parameters();
      let incomingOption: any = new Parameters();
      let incomingOpt: any = new Parameters();
      let incomingQ: any   = new Parameters();
      if(optional.hasOwnProperty('questions')){
        optional.questions.map((question: any) => {
          incomingQuestion.setAttributes(question);
          this.questions.map((q: any, key: any) => {
            incomingQ.setAttributes(q);
            if(incomingQ.attributes.cntrl_num.toString().includes(incomingQuestion.attributes.cntrl_num.toString())){
              if(incomingQ.attributes.control.id === 12){}
              else if(incomingQ.attributes.control.id === 18){
              } else callback(1);
              incomingQ.attributes.options.map((opt: any) => {
                incomingOpt.setAttributes(opt);
                if (incomingQ.attributes.control.id === 5) {
                  if (!this.service.isNull(incomingQ.attributes.data)) {
                  if(incomingQ.attributes.data.toString() === incomingOpt.attributes.label.toString())
                  this.countOptionalQuestions(resultOpt, opt, callback);
                  }
                } else this.countOptionalQuestions(resultOpt, opt, callback);
              });
            }
          });
        });
      }
    }catch (e) {
    }
  }


  countOptionWrittenQuestions(resultOpt: any, optional: any, callback: any){
    try{
      let incomingQuestion: any = new Parameters();
      let incomingOption: any   = new Parameters();
      let incomingQ: any = new Parameters();
      if(optional.hasOwnProperty('questions')){
        optional.questions.map((question: any) => {
          incomingQuestion.setAttributes(question);
          this.questions.map((q: any, key: any) => {
            incomingQ.setAttributes(q);
            if(incomingQ.attributes.cntrl_num.toString().includes(incomingQuestion.attributes.cntrl_num.toString())){
              if(incomingQ.attributes.control.id === 12){}
              else if(incomingQ.attributes.control.id === 18){} else{
                if(parseFloat(incomingQ.getControlId()) === 24){
                  if(incomingQ.attributes.hasOwnProperty('is_null')){
                    if(!Boolean(incomingQ.getIsNull())){
                      callback(1);
                    }
                  }
                }else{
                  if(!this.service.isNull(incomingQ.attributes.data)){
                    if(incomingQ.attributes.data.toString() !== 'null') {
                      if(parseFloat(incomingQ.getControlId()) === 3 ){
                        if(!this.service.isNull(incomingQ.getAtleastOptionalSelections())){
                          if(parseFloat(incomingQ.getAtleastOptionalSelections()) <= incomingQ.getData().toString().split(this.service.separator).length)
                            callback(1);
                        }else callback(1);
                      }else callback(1);
                    }
                  }
                }
              }
              incomingQ.attributes.options.map((opt: any) => {
                if (incomingQ.attributes.control.id === 5) {
                  if (!this.service.isNull(incomingQ.attributes.data)) {
                    if(incomingQ.attributes.data.toString() === opt.label.toString()){
                      this.countOptionWrittenQuestions(resultOpt, opt, callback);
                    }
                  }
                } else this.countOptionWrittenQuestions(resultOpt, opt, callback);
              });
            }
          });
        });
      }
    }catch (e) {
    }
  }


  getOptionalProgress(optional: any, callback){
      this.countOptionalQuestions(optional, optional,(rs: any) => { callback({all: rs}); });
      this.countOptionWrittenQuestions(optional, optional, (rs: any) => { callback({written: rs}); });
  }

  progress(filled: any, all: any){
    // tslint:disable-next-line:radix
    return all === 0 ? 0 : (parseFloat(filled) / parseFloat(all) * 100);
  }

  homeGroup(){
    this.question = this.questions.filter((question: any) => {
    return question.cntrl_num.toString().includes(this.group.cntrl_num.toString()); })[0];
    this.position = this.question.index;
    this.group = null;
    this.clearGroupQuestions();
    return this.question;
  }

  hideQuestionOptionsWithoutClearData(question: any){
    question.options.map((option: any) => {
      this.hideOptionalQuestionsWithoutClearData(option);
    });
  }

  setQuestionsMainGroup(groupOptional: any, optional){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((question: any) => {
        this.questions.map((q: any, l: any) => {
          if(q.cntrl_num.toString().includes(question.cntrl_num)) {
            q = Object.assign(q, {main: groupOptional.id});
            q.options.map((opt: any) => {
              this.setQuestionsMainGroup(groupOptional, opt);
            });
          }
        });
      });
    }
  }

  offQuestionOptionsWithoutClearData(question: any, callback){
    question.options.map((option: any) => { this.offOptionalQuestionsWithoutClearData(option, callback);});
  }

  getQuestionalParent(child: any, callback: any){
    setTimeout(() => {
      this.questions.map((questional: any) => {
        questional.options.map((opt: any) => {
          opt.questions.map((q: any) => {
            if (q.cntrl_num.toString().includes(child.cntrl_num)) {
              callback({main: questional.cntrl_num, child: child.cntrl_num});
            }
          });
        });
      });
    });
  }

  timeout(){
 // tslint:disable-next-line:radix
    return parseInt('1020');
  }


  setQuestionspositional(){
    this.questions.map((questional: any, positionalStart: any) => {
      if(questional.visible === 1){
        questional.index = positionalStart;
        this.setQuestionalOptionalspositions(questional, questional.index,() => {});
      }

    });
  }

  setQuestionalOptionalspositions(questional, positionalStart, callback){
    questional.options.map((optional: any) => {
      if(optional.hasOwnProperty('questions')){
        optional.questions.map((question: any) => {
          this.questions.map((q: any, l: any) => {
            if(q.cntrl_num.toString().includes(question.cntrl_num)) {
              q.index = positionalStart + '' + l;
              q.options.map((opt: any) => {
                this.setQuestionalOptionalspositions(q, q.index, callback);
              });
            }
          });
        });
      }
    });
  }


  isQuestionInGroupOptional(groupOptional: any, question: any, callback: any){
    this.checkQuestionInGroupOptional(groupOptional, question, callback);
  }


  checkQuestionInGroupOptional(groupOptional: any, questionalChecked: any, callback: any) {
    try {
      if (groupOptional.hasOwnProperty('questions')) {
        groupOptional.questions.map((question: any) => {
          this.questions.map((q: any, l: any) => {
            if (q.cntrl_num.toString().includes(question.cntrl_num)) {
              if (q.cntrl_num.toString().includes(questionalChecked.cntrl_num.toString())) {
                callback({result: true, questional: questionalChecked, optional: groupOptional});
              }else{
                q.options.map((opt: any) => {
                  this.checkQuestionInGroupOptional(opt, questionalChecked, callback);
                });
              }
            }
          });
        });
      }
    } catch (e) {
    }
    return null;
  }

  getQuestionsNotInGroup(group: any, status: any){
    let questions: any = this.questions.filter((questional: any) => {
      if(status === 1){
        if(group.index < questional.index &&
            questional.visible === 1) {
          return questional;
        }
      }else if(status === 0) {
        if (group.index > questional.index &&
            questional.visible === 1) {
          return questional;
        }
      }
    });
    return questions;
  }

  clearGroupQuestions(){
    this.groupOptional = null;
    this.questions.map((question: any) => {
      if(question.hasOwnProperty('group')){
       delete question.group;
      }
    });
  }

  hasQuestionInChoice(question: any){
    let l: any = false;
    this.choiceQuestional.options.map((option: any) => {
      option.questions.map((q: any) => {
      });
    });
  }


  appendGroupSign(q: any){
    if(this.group === null){
      if(q.hasOwnProperty('group')) delete q.group;
      if(q.hasOwnProperty('groupOptional')) delete q.groupOptional;
    }else {
    q.group = this.group.id;
    q.groupOtional = this.groupOptional.id;
    if(this.choiceQuestional !== null) q.choiceQuestional = this.choiceQuestional;
    this.groupQuestions.push(q);
    }
  }

  hideOptionalQuestions(optional: any){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((question: any) => {
        this.questions.map((q: any, l: any) => {
          if(q.cntrl_num.toString().includes(question.cntrl_num)) {
            q.visible = 0;
            q.data = q.control.id === 13 ? '0' : '';
            q.result = {};
            q.options.map((opt: any) => {
              this.hideOptionalQuestions(opt);
            });
          }
        });
      });
    }
  }

  deleteQuestion(question: any){
    this.questions.splice(this.service.findIndex(this.questions, 'cntrl_num', question.cntrl_num), 1);
    this.positions();
    if(question.hasOwnProperty('id')) this.deletedQuestions.push(question);
    this.broadcastChannelService.emitNavChangeEvent({ questional: 'delete', questionalId: question.id});
  }


  getVisible(){
    let l: any = 0;
    try {
      this.questions.map((question: any, key: any) => {
        let incomingQuestional: any = new Parameters();
        incomingQuestional.setAttributes(question);
        if(incomingQuestional.hasOwnProperty('visible')){
          if(parseFloat(incomingQuestional.getVisible()) === 1){
           l += 1;
          }
        }
      });
    }catch (e) {}
    return l;
  }

  getlastVisibleQuestion(){
    return this.questions.filter((question: any) => { return question.visible === 1; }).reverse()[0];
  }



  hideOptionalQuestionsWithoutClearData(optional: any){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((question: any) => {
        this.questions.map((q: any, l: any) => {
          if(q.cntrl_num.toString().includes(question.cntrl_num)) {
            q.visible = 0;
            q.options.map((opt: any) => {
              this.hideOptionalQuestionsWithoutClearData(opt);
            });
          }
        });
      });
    }
  }


  offOptionalQuestionsWithoutClearData(optional: any, callback){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((question: any) => {
        this.questions.map((q: any, l: any) => {
          if(q.cntrl_num.toString().includes(question.cntrl_num)) {
            q.visible = 0;
            callback({item: q.cntrl_num});
            q.options.map((opt: any) => {
              this.offOptionalQuestionsWithoutClearData(opt, callback);
            });
          }
        });
      });
    }
  }

  update(incomingForm: any, call: any){
    this.service.httpService('post', '/forms/null/update',
        incomingForm.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
          incomingForm.attributes = Object.assign(incomingForm.getAttributes(), response);
          call(incomingForm.getAttributes());
        }, (error: any) => {
           call(null);
        });
  }


  updateSettings(incomingForm, call: any){
    setTimeout(() => {
      let outgoingRequest: any = new Parameters();
      outgoingRequest.setAttributes(Object.assign({}, incomingForm.getAttributes()));
      outgoingRequest.setId(incomingForm.getId());
      outgoingRequest.setSettings(JSON.parse(JSON.stringify(incomingForm.getSettings())));
      this.service.httpService( 'POST',
          this.service.app.routes.forms.settings.update, outgoingRequest.getAttributes(),
          {ignoreLoadingBar: 'true', notify: 'false'},(response: any) => {
           call({});
          },  (error: any) => {});
    });
  }

  hasVisibleQuestions(){
    var l: any = false;
    this.questions.map((questional: any) =>{
      // tslint:disable-next-line:radix
      if(parseInt(questional.visible) === 1){
        l = true;
      }
    });
    return l;
  }


  getForm(form: any){
    var forms: any = [] = this.service.app.data.forms.data.filter((frm: any) => {
      return parseFloat(frm.id) === parseFloat(form.id); });
    return forms.length === 0 ? this.addForm(form) : forms[0];
  }

  addForm(form: any){
    this.service.app.data.forms.data.unshift(form);
    return form;
  }

  getFormById(formId: any){
    var forms: any = [] = this.service.app.data.forms.data.filter((frm: any) => {
      return parseFloat(frm.id) === parseFloat(formId);});
    return forms[0];
  }



  search(outgoingRequest: Parameters, call: any){
    this.service.httpService(
        'POST',
        this.service.app.routes.forms.search,
        outgoingRequest.getAttributes(), {notify: 'false'},
        (forms: any) => {
          call(forms);
        }, (error: any) => {call(null);});
  }

  requestFormByFormId(formId: any, call: any){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setFormId(formId);
    outgoingRequest.setId(formId);
    this.service.httpService(
        'POST',
        this.service.app.routes.forms.get.toString(),
        outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          call(response);
        }, (error: any) => {
        });
  }

  openDialog(params: any) {
    if(this.isOpenDialog === false) {
      this.isOpenDialog = true;
      setTimeout(() => {
        var dialogRef: any = this.dialog.open(DialogFormsComponent, {
          disableClose: true,
          data: {params: params},
          width: '70%',
          height: '100%',
          autoFocus: true,
        });
        dialogRef.afterClosed().subscribe(() => {
          this.isOpenDialog = false;
        });
      });
    }
  }

  loadHiddenQuestions(){
    setTimeout(() => {
      this.questions.map((question: any) => {
        question.options.map((opt: any) => {
          this.hideOptionalQuestions(opt);
        });
      });
    });
  }

  create(form: any, userValues: any, callback: any){
    setTimeout(() => {
      this.formValues.setAttributes(form);
      this.responseValues.setAttributes({});
      this.responseValues.setFormId(this.formSelectedId);
      this.responseValues.setStartingAt(moment(new Date()).format('HH:mm:ss'));
      this.responseValues.setAltitude(0);
      this.responseValues.setDeviceUsage(this.service.getBrowserName());
      this.responseValues.setDepartmentId(1);
      this.responseValues.setAddress('');
      this.responseValues.setTitle('Untitled');
      this.responseValues.setStatus(0);
      this.responseValues.setLat(0);
      this.responseValues.setLng(0);
      this.responseValues.setPostId(this.service.random());
      this.responseValues.setCntrlNum(this.responseValues.attributes.post_id);
      this.responseValues.setForm(form);
      this.responseValues.setDate(moment(new Date()).format('MM/DD/YYYY'));
      if(Object.keys(userValues.getAttributes()).length === 0){
        window.localStorage.setItem(this.formValues.attributes.id.toString(), JSON.stringify(this.formValues.attributes));
      }else this.responseValues.setPostedBy(this.service.app.data.user);
      callback(this.responseValues.getAttributes());
    });
  }

  addOther(){
    setTimeout(() => {
      this.incomingOption.setAttributes(this.newOptional);
      this.storage.get('others').subscribe((others: any) => {
          if(others){
            if (others instanceof Array) {
              others.push(this.incomingOption.getAttributes());
              this.storage.set('others', others).subscribe(() => {});
            }
          }else this.storage.set('others', [this.incomingOption.getAttributes()]).subscribe(() => {});
      });
    });
  }

  removeOther(){
    this.incomingOption.setAttributes(this.newOptional);
    setTimeout(() =>{
      this.storage.get('others').subscribe((others: any) => {
        if(others){
          if (others instanceof Array) {
            let position: any = this.service.findIndex(others, 'cntrl_num', this.incomingOption.getCntrlNum());
            if(position !== -1){
              others.splice(position, 1);
              this.storage.set('others', others).subscribe(() => {});
            }
          }
        }
      });
    });
  }

  getVisibleQuestions(questions: any){
    this.outgoingQuestions = [];
    this.outgoingQuestions = questions.filter((question: any) => {
      this.incomingQuestion.setAttributes(question);
      return parseFloat(this.incomingQuestion.getVisible()) === 1;
    });return this.outgoingQuestions;
  }

  setQuestionsVisible(questions: any){
    this.outgoingQuestions = [];
    this.outgoingQuestions = questions.map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      this.incomingQuestion.setVisible(1);
      return this.incomingQuestion.getAttributes();
    });return this.outgoingQuestions;
  }

  setOptionQuestionsUnvisible(questions: any){
    if(questions instanceof Array) {
      this.outgoingQuestions = [];
      this.outgoingQuestions = questions.map((question: any) => {
        this.incomingQuestion.setAttributes(question);
        this.incomingQuestion.getOptions().map((option: any) => {
          this.incomingOption.setAttributes(option);
          this.incomingOption.getQuestions().map((optionalQuestion: any) =>{
            this.incomingOptionalQuestion.setAttributes(optionalQuestion);
            this.getQuestionByQuestionsAndCntrlNum(questions, this.incomingOptionalQuestion.getCntrlNum()).visible = 0;
          });});return this.incomingQuestion.getAttributes();
      });return this.outgoingQuestions;
    }else return {};
  }


  isTableNull(question: any){
    let results: any = false;
    this.incomingItem.setAttributes(question);
    return this.incomingItem.getIsNull();
    // if(parseFloat(this.incomingItem.getRequired()) === 1){
    //   this.incomingItem.getRows().map((row: any) => {
    //     this.incomingRow.setAttributes(row);
    //     for(var key in this.incomingRow.getAttributes()){
    //       if(this.incomingRow.getUnknown(key).hasOwnProperty('datea') &&
    //           !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
    //           this.service.empty(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data)))
    //         results = true;
    //     }
    //   });
    // }else{
    //   results = true;
    //   this.incomingItem.getRows().map((row: any) => {
    //     this.incomingRow.setAttributes(row);
    //     for(var key in this.incomingRow.getAttributes()){
    //       if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
    //           !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
    //           !this.service.empty(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data)))
    //         results = false;
    //     }
    //   });
    // }
    // return results;
  }




  updateOther(label: any){
    this.incomingOption.setAttributes(this.newOptional);
    setTimeout(() =>{
      this.storage.get('others').subscribe((others: any) => {
        if(others){
          if (others instanceof Array) {
            let position: any = this.service.findIndex(others, 'cntrl_num', this.incomingOption.getCntrlNum());
            if(position !== -1){
              let other: any = others[position];
              other = Object.assign(other, {label: label});
              this.storage.set('others', others).subscribe(() => {});
            }
          }
        }
      });
    });
  }



  copyUrl(){
    let url = window.location.host + '/app/forms/' + this.service.encrypt(this.formSelectedId) + '/respond';
    document.execCommand('copy');
    if(navigator.clipboard){
      if(window.isSecureContext){
        navigator.clipboard.writeText(url).then(() => {}, (err: any) => {});
        this.notifier.notify('success', 'copyed !!');
      }
    }else{
      const copyTextarea: any = document.createElement('textarea');
      copyTextarea.style.position = 'fixed';
      copyTextarea.style.opacity = '0';
      copyTextarea.textContent = url;
      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(copyTextarea);
      this.notifier.notify('success', 'copyed !!');
    }
  }

  loadShowQuestions(){
    this.questions.map((question: any) => {
      question.visible = 1;
      question.options.map((opt: any) => {
        this.showAllQuestions(opt);
      });
    });
  }

  showAllQuestions(optional: any){
    if(optional.hasOwnProperty('questions')){
      optional.questions.map((question: any) => {
        this.questions.map((q: any, l: any) => {
          if(q.cntrl_num.toString().includes(question.cntrl_num.toString())){
            q.visible = 1;
            q.options.map((opt: any) => {
              this.showAllQuestions(opt);
            });
          }
        });
      });
    }
  }

  getQuestionByLabel(label: any){
    this.questionValues = new Parameters();
    return this.questions.filter((question: any) => {
     this.questionValues.setAttributes(question);
     return this.questionValues.getLabel().toString().trim().includes(label.toString().trim());
   })[0];
  }

  hideQuestionOptionals(question: any, callback){
    this.questions.map((q: any) => {
      if(q.cntrl_num.toString().includes(question.cntrl_num.toString())){
        q.visible = 0;
      }
    });
    callback({status: 'successfull'});
  }


  hasQuestionByLabel(label: any){
    this.questionValues = new Parameters();
    let filteredValues: any = this.questions.filter((question: any) => {
      this.questionValues.setAttributes(question);
      return this.questionValues.getLabel().toString().trim().includes(label.toString().trim());
    });return filteredValues.length !== 0;
  }

  clearChoiceOptions(choicQuestional: any){
    choicQuestional.options.map((optional: any) => {
      if(optional.hasOwnProperty('questions')){
        optional.questions.map((question: any) => {
          this.questions.map((q: any, l: any) => {
            if(q.cntrl_num.toString().includes(question.cntrl_num.toString())){
              q.options.map((opt: any) => {
                this.showAllQuestions(opt);
              });
            }
          });
        });
      }
    });
  }

  getQuestionPositional(question : any){
    let l = null;
    this.questions.map((item: any, key: any) => {
      if(item.cntrl_num.toString().includes(question.cntrl_num.toString())){
       l = key;
      }
    });
    return l;
  }

  openFormSetting(){
    if(this.isOpenDialog === false) {
      this.isOpenDialog = true;
      setTimeout(() => {
        const dialogRef = this.dialog.open(SettingsFormComponent, {
          disableClose: false,
          data: {},
          width: '70%',
          height: '100%',
          autoFocus: true,
        });
        dialogRef.afterClosed().subscribe(() => {
          this.isOpenDialog = false;
        });
      });
    }
  }

  manageRespondents(form: any){
    this.setSelected(form);
    if(this.isOpenDialog === false) {
      this.isOpenDialog = true;
      setTimeout(() => {
        const dialogRef = this.dialog.open(ListTeamsComponent, {
          disableClose: false,
          data: {tabAllign: 'end', campaignSelectedId: form.campaign_id},
          width: '55%',
          height: '99%',
        });
        dialogRef.afterClosed().subscribe(() => {
         this.isOpenDialog = false;
        });
      });
    }
  }


  nodes(){
    // const treedata: any = [];
    // this.formService.loadHiddenQuestions();
    // this.questions.map((question: any) => { this.appendChildrenNode(question, 0);});
    // this.dataSource.data = this.treedata;
    // return this.dataSource;
  }
}
