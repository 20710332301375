<div id="example-radio-group-label"
    class="cursor"
     [ngStyle]="{'color': this.color,'padding':'0px 50px 0 50px','margin-bottom':  incomingQuestion.getLabel() === '&nbsp;' ? '10px' : '100px'}"
    #element>
    <center>
        <ng-container *ngIf="!service.isNull(incomingQuestionSettings.getSrc())">
            <img [src]="incomingQuestionSettings.getSrc()"
                 [alt]="'img'"
                 [class]="'mdl-shadow--2dp'"
                 [ngStyle]="{'width':'100%','max-height':'450px'}"/>
        </ng-container>
    </center>
    <h4>
        <table>
            <td>
                <span class="" [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(incomingQuestion.getRequired()) === 1 ? '* ' : '') }}</span>
            </td>
            <td>
                 <span [ngStyle]="{'color': this.color}" class="">
                    <div style="text-align: left" class="cursor sawasdee20" *ngIf="service.parseFloat(incomingForm.getCategoryId())===4" [innerHTML]="incomingQuestion.getLabel()"></div>
                    <div style="text-align: left" class="cursor sawasdee20" *ngIf="service.parseFloat(incomingForm.getCategoryId())!==4">{{service.upperStartingCharacter(service.removeHtmlTags(incomingQuestion.getLabel()), true)}}</div>
                </span>
            </td>
        </table>
    </h4>
</div>