<div layout="row">
  <div class="col-lg-2 col-md-2"></div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
      <div class="panel-bodyy">
        <div>
          <app-label-create-control [question]="question"></app-label-create-control>
        </div>
        <div>
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text"
                   class="sawasdee26"
                   autocomplete="off"
                   placeholder="{{question.placeholder}}"
                   matInput
                   #numberElement
                   [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                   [readonly]="isAutoAggreted()"
                   (keydown)="validateNumber($event)"
                   (keyup.enter)="onMouseEnter()"
                   (blur)="service.decimalPlaces($event,incomingConditions.getAttributes())"
                   (keyup)="service.numberValidation($event,incomingConditions.getAttributes());addComma(numberElement)"
                   [(ngModel)]="question.data">
          </mat-form-field>
        </div>
        <div class="" style="width: 100%">
          <div class="pull-right">
            <app-next-button></app-next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2"></div>
</div>


