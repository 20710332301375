<div layout="row" class="">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div>
            <app-label-create-control [question]="question"></app-label-create-control>
            <br><br>
        </div>
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy chekboxClass">
                <ng-container *ngIf="hasNodes(); else noNodes">
                    <mat-accordion [multi]="true">
                        <mat-expansion-panel [expanded]="checkbox.checked"
                                             #matExpansionPanelId
                                             [ngStyle]="{'color':incomingSettings.getColor()}"
                                             *ngFor="let option of question.options">
                            <mat-expansion-panel-header
                                    (click)="onClick(checkbox,option);">
                                <mat-panel-title>
                                    <mat-checkbox
                                        #checkbox
                                        class="pull-left"
                                        [disabled]="true"
                                        [ngModel]='question.data.includes(option.cntrl_num)'
                                        (contextmenu)="onContextMenu($event, option)"
                                        (mousedown)="incomingSelectedOption.setAttributes(option);"
                                        [value]="option">
                                           <span ngClass.md="sawasdee18"
                                                 ngClass.xl="sawasdee18"
                                                 ngClass.lg="sawasdee18"
                                                 ngClass.sm="sawasdee18"
                                                 ngClass.xs="sawasdee18"
                                                 [ngStyle]="{'color': this.incomingSettings.getColor()}">{{service.upperStartingCharacter(option.label, true)}}
                                           </span>
                                    </mat-checkbox>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div [ngStyle]="{'margin-top': '10px'}" *ngIf="checkbox.checked && option.questions.length !== 0">
                                <ul class="mdl-list">
                                    <li class="mdl-list__item example-full-width" *ngFor="let question of option.questions; let l = index">
                                        <center>
                                            <div class="sawasdee18 text-left" [ngStyle]="{'color':this.incomingSettings.getColor()}">{{this.service.upperStartingCharacter(question.label, true)}}</div><br><br>
                                            <div>
                                                <ng-container *ngIf="question.control.id === 5; else number">
                                                    <mat-radio-group
                                                            aria-labelledby="example-radio-group-label"
                                                            class="example-radio-group">
                                                        <mat-radio-button
                                                                class="example-radio-buttonn"
                                                                #radio
                                                                *ngFor="let option of question.options"
                                                                [checked]="isradionChecked(radio, question, option)"
                                                                (click)="afterradioChecked(radio, question, option)"
                                                                [value]="option">
                                                            <div class="sawasdee16 text-left" [ngStyle]="{'color':this.incomingSettings.getColor()}">{{service.setSmallCharacters(option.label)}}</div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </ng-container>
                                                <ng-template #number>
                                                    <ng-container *ngIf="question.control.id === 10; else description">
                                                        <mat-form-field class="example-full-width" appearance="fill">
                                                            <input type="text"
                                                                   class="sawasdee18"
                                                                   autocomplete="off"
                                                                   placeholder="{{question.placeholder}}"
                                                                   matInput
                                                                   #numberId
                                                                   [ngStyle]="{'text-align': 'center', 'color':this.incomingSettings.getColor()}"
                                                                   [(ngModel)]="this.formService.getQuestionByCntrlNum(question.cntrl_num).data">
                                                        </mat-form-field>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #description>
                                                    dfdbkjfdfjdfd
                                                </ng-template>
                                            </div>
                                        </center>
                                    </li>
                                </ul>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-template #noNodes>
                    <div >
                        <div class='pg'>
                            <center>
                                <div class="{{service.parseFloat(incomingSettings.attributes.lfd) === 1 ? 'hidden-lg' : ''}}">
                                    <table>
                                        <tr *ngFor="let option of question.options">
                                            <td class="cursor">
                                                <mat-icon *ngIf="option.questions.length !== 0 && checkbox.checked"
                                                          [ngStyle]="{'color':incomingSettings.getColor()}">
                                                    <i class="fa fa-plus-circle"></i>
                                                </mat-icon>
                                            </td>
                                            <td class="cChoicex">
                                                <mat-checkbox
                                                    #checkbox
                                                    class="pull-left"
                                                    [ngModel]='question.data.includes(option.cntrl_num)'
                                                    (contextmenu)="onContextMenu($event, option)"
                                                    (mousedown)="incomingSelectedOption.setAttributes(option);"
                                                    (change)="onClick(checkbox,option)"
                                                    [value]="option">
                                           <span ngClass.xl="sawasdee18"
                                                 ngClass.lg="sawasdee18"
                                                 ngClass.md="sawasdee18"
                                                 ngClass.sm="sawasdee18"
                                                 ngClass.xs="sawasdee18"
                                                 class="text-left"
                                                 [ngStyle]="{'color': formService.color}">{{service.upperStartingCharacter(option.label, true)}}
                                           </span>
                                                </mat-checkbox>
                                            </td>
                                            <!--                    <td *ngIf="-->
                                            <!--                                option.settings.hasOwnProperty('src') && -->
                                            <!--                                 this.service.empty(option.settings.src)">-->
                                            <!--
                                                       <div class='pull-right'>-->
                                            <!--                        <img [src]="option.settings.hasOwnProperty('src') ? option.settings.src : ''"-->
                                            <!--                             [alt]="'img'"-->
                                            <!--                             ngClass="mdl-shadow&#45;&#45;2dp img-circle"/>-->
                                            <!--                      </div>-->
                                            <!--                    </td>-->
                                        </tr>
                                    </table>
                                </div>
                                <div class="{{service.parseFloat(incomingSettings.attributes.lfd) === 0 ? 'hidden-lg' : ''}} hidden-sm hidden-xs  hidden-md">
                                    <section class="post-section blog-post" style="background-color: transparent !important;">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"
                                                     *ngFor="let option of question.options"
                                                     (click)='onOptionSelected(option)'>
                                                    <div class="post-box" style="background-color:{{question.data.includes(option.cntrl_num) ? 'gold' : ''}} !important">
                                                        <div class="text-box">
                                                            <div class='pull-left'>
                                                                <mat-checkbox
                                                                    #checked
                                                                    [checked]="question.data.includes(option.cntrl_num)"
                                                                    [value]="option">
                                                                        <span class="sawasdee18 text-left" [ngStyle]="{'color': '#5A738E'}">
                                                                          {{service.upperStartingCharacter(option.label, true)}}
                                                                        </span>
                                                                </mat-checkbox>
                                                            </div>
                                                            <div class='pull-right' *ngIf="
                                option.settings.hasOwnProperty('src') &&
                                 !this.service.isNull(option.settings.src)">
                                                                <img [src]="option.settings.hasOwnProperty('src') ? option.settings.src : ''"
                                                                     [alt]="'img'"
                                                                     [class]="'mdl-shadow--2dp img-circle'"
                                                                     [ngStyle]="{'width':'60px','height':'60px'}"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </center>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <br><app-other-option-questions [question]="question" [position]="position"></app-other-option-questions>
        </div>
        <div class="row">
            <center>
                <app-next-button></app-next-button>
            </center>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="handleUpdate(incomingSelectedOption.getAttributes())">Edit</button>
        <button mat-menu-item (click)="removeOption(incomingSelectedOption.getAttributes())">Delete</button>
    </ng-template>
</mat-menu>
