<!--INNER BANNER START-->
<section id="inner-banner">
    <div class="container">
        <h1></h1>
    </div>
</section>
<!--INNER BANNER END-->
<!--MAIN START-->
<div id="main">
    <!--SIGNUP SECTION START-->
    <section class="signup-section">
        <div class="container">
            <div class="holder">
                <div class="thumb"><img src="{{appService.logo}}" alt="img" style="width: 85px; height: 85px"></div>
                <form [formGroup]=parameters
                      (ngSubmit)="onSubmit(parameters.value)"
                      (keydown.enter)="$event.preventDefault()">
                    <div class="input-box">
                        <i class="fa fa-envelope"></i>
                        <input type="text"
                               name="email"
                               formControlName="email"
                               placeholder="Email"  />
                    </div>
                    <div class="input-box">
                        <i class="fa fa-unlock"></i>
                        <input type="password"
                               name="password"
                               formControlName="password"
                               placeholder="Password" />
                    </div>
                    <div class="check-box">
                        <div class="checkboxArea" id="myCheckbox0"></div>
                        <input id="id3" type="checkbox" class="outtaHere">
                        <strong>Remember Me</strong>
                    </div>
                    <input type="submit" value="Login"  [disabled] = "!parameters.valid" stop-propagation>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ng-container  *ngIf="this.appService.parseFloat(this.appService.host.id) !== 1">
                            <a class="login">Forgot your Password </a>
                            <b>OR</b>
                        </ng-container>
                    </div>
                    <div class="login-social" *ngIf="this.appService.parseFloat(this.appService.host.id) !== 1">
                        <h4>Log in with Your Social Account</h4>
                        <ul>
                            <li fb-login (click)="signInWithFB()" type="button"> <a class="fb cursor"><i class="fa fa-facebook"></i>Facebook Login</a> </li>
                            <li g-login  (click)="signInWithGoogle()" type="button"> <a  class="gp cursor"><i class="fa fa-google-plus"></i>Google Plus Login</a> </li>
                            <li tw-login (click)="signInWithTwitter()"> <a  class="tw cursor"><i class="fa fa-twitter"></i>Twitter Login</a> </li>

                        </ul>
                        <em>You Don’t have an Account? <a href="app/providers/register">SIGN UP NOW</a></em>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!--SIGNUP SECTION END-->
</div>