import {Component, NgZone, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from '../../forms/form.service';
import {Parameters} from '../../parameters';
import Echo from 'laravel-echo';
import {DialogViewImageComponent} from '../dialog/dialog-view-image/dialog-view-image.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-images-partial',
  templateUrl: './images-partial.component.html',
  styleUrls: ['./images-partial.component.scss']
})
export class ImagesPartialComponent implements OnInit {
  incomingForm: any;
  strings: any;
  play: any = true;
  images: any = [];
  ispullingdata: any = true;
  isinternetavailable: any = true;
  dialogRef: any=null;
  constructor(public service: AppService,
              public  dialog: MatDialog,
              public zone: NgZone,
              private formService: FormService) {
    this.incomingForm = new Parameters();
    this.strings = new Parameters();
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.strings.setAttributes(this.service.app.strings.params);
    this.images = this.service.app.data.images.data.filter((image: any) => {return parseFloat(image[this.strings.getFormId()]) === parseFloat(this.incomingForm.getId());});
  }

  ngOnInit(): void {
    this.images =
        this.service.orderBy(
        this.images,
        this.strings.getDesc(),
        this.strings.getId());
    this.getImages(10, (call: any) => {});
    const echo: any = new Echo({
      broadcaster : this.strings.getBroadcasterDriver(),
      key         : this.strings.getWebsocketKey(),
      wsHost      : this.strings.getWsHost(),
      wsPort      : parseFloat(this.strings.getWsPort()),
      disableStats: false,
    });
    echo.channel(
        this.strings.getWebsocketChannelResponse()).listen('.' +
        this.strings.getWebsocketEventResponse(), (e: any ) => {
      this.zone.run(() => {
        let incomingResult: any = new Parameters();
        incomingResult.setAttributes(JSON.parse(e[0]));
        if(parseFloat(this.incomingForm.getId()) === parseFloat(incomingResult.getFormId())) {
          setTimeout(() => {
            if(!this.ispullingdata &&
                this.isinternetavailable)
              this.getImages(10, (call: any) => {});
          });
        }
      });
    });
    this.service.checkNetworkStatus((network: any) => {
      if(!network) this.isinternetavailable = false; else {
        if (!this.isinternetavailable && !this.ispullingdata){
          this.getImages(10, (call: any) => {});
        }
      }
    });
  }




  view(imageSelected: any){
    this.dialogRef = this.dialog.open(DialogViewImageComponent, {
      data        : {images: this.images, image: imageSelected, width: this.service.getWidth() / 2, height: this.service.getHeight()},
      width       : (this.service.getWidth() / 2).toString(),
      height      : this.service.getHeight().toString(),
      hasBackdrop : true,
      disableClose: false,
    });
  }

  getImages(limit: any, call: any){
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setFormId(this.incomingForm.getId());
    if(this.images.length !== 0)
      outgoingRequest.setAscendinglastAt(this.images[this.images.length - 1][this.strings.getId()]);
    this.service.httpService('POST',
        this.service.app.routes.images.search,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar: 'true', notify: false},
        (images: any) => {
          images.map((order: any) => {
            let incomingImage: any = new Parameters();
            incomingImage.setAttributes(order);
            if(this.service.findIntegerIndex(this.images, this.strings.getId(), incomingImage.getId()) === -1)
              this.images.push(incomingImage.getAttributes());
          });
          if(images.length !== 0){
            limit -= parseFloat('1');
            this.getImages(limit, call);
          }else {this.service.matSpinner = false;
            this.ispullingdata = false;
          }
        },(error: any) => {
          setTimeout(() => {
            this.getImages(limit, call);
          });
        });
  }

}
