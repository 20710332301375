<div layout="row">
    <div class="panell panel-defaultt btext" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-bodyy">
            <div>
                <app-label-create-control [question]="question"></app-label-create-control><br><br>
            </div>
            <div class="row">
                <div class="col-md-0"></div>
                <div class="col-md-lg col-md-12 col-sm-12 col-xs-12">
                    <div class="defaultColor gbox" style="background-color: #fff !important;">
                        <center>
                            <section class="testimonials" style="background:transparent;margin:0px;">
                                <section class="testimonials-style-3">
                                    <div class="container">
                                        <div class="row">
                                            <div layout="row">
                                                <ng-container >
                                                    <ul class="list-inline">
                                                        <li class="" *ngFor="let option of question.options">
                                                            <div  class="{{incomingQuestion.attributes.result.hasOwnProperty('cntrl_num') && incomingQuestion.attributes.result.cntrl_num === option.cntrl_num ? 'gboxitem ' : ''}} item  cursor"
                                                                  (click)="onImageSelected(option)"
                                                                  [ngStyle]= "{'width':'auto','margin-right':'10px'}">
                                                                <div class="col">
                                                                    <p class="cursor sawasdee18">{{service.upperStartingCharacter(option.label, true)}}</p>
                                                                </div>
                                                                <div class="thumb" style="background-color: rgba(0,0,0,1) !important;">
                                                                    <img class="" src="{{option.media ? option.media : 'assets/images/avatar.jpg'}}"
                                                                         alt="img"
                                                                         [ngStyle]= "{'position':'relative','top':'0'}">
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </center>
                    </div>
                </div>
                <div class="col-md-0"></div>
            </div>
        </div>
    </div>
</div>
