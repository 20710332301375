import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../app.service';
import {FormService} from '../../../forms/form.service';
import {Parameters} from '../../../parameters';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResponseService} from '../../../forms/responses/data/response.service';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {NotifierOptionsToken, NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxQrcodeStylingService, Options} from 'ngx-qrcode-styling';
import html2canvas from 'html2canvas';
import {ImageService} from "../../../images/image.service";
import * as $ from "jquery";
import {ClientService} from "../../../clients/client.service";
import {StorageMap} from "@ngx-pwa/local-storage";
import addListener = google.maps.event.addListener;
@Component({
  selector: 'app-register-events',
  templateUrl: './register-events.component.html',
  styleUrls: ['./register-events.component.scss']
})
export class RegisterEventsComponent implements OnInit, OnDestroy {
  @ViewChild('recaptchaId') recaptchaId: any;
  formValues: FormGroup = new FormGroup({});
  incomingForm: any;
  incomingFormSettings: any;
  incomingClient: any;
  incomingCampaign: any;
  outgoingParams: any;
  pdfUrl: any = null;
  canvas: any = null;
  strings: any;
  questions: any = [];
  incomingQuestion: any;
  outgoingResponse: any;
  siteKey = '6Lcc-W0UAAAAAAdcFGL0d47jvGH5Eemf2DqBiOwK';
  recaptcha: any = '';
  formId: any;
  incomingInvitationCard: any;
  incomingNameQuestion: any;
  incomingPhoneQuestion: any;
  incomingCompanyQuestion: any;
  incomingEmailQuestion: any;
  incomingPackages: any;
  incomingInvitee: any;
  pg: any = 0;
  outgoingRequest: any;
  status: any = 0;
  constructor(public service: AppService,
              private responseService: ResponseService,
              private qrcode: NgxQrcodeStylingService,
              private clientService: ClientService,
              public formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private storage: StorageMap,
              private imgService: ImageService,
              public zone: NgZone,
              private router: Router,
              private notifier: NotifierService,
              public formService: FormService) {
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingInvitee = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingCompanyQuestion = new Parameters();
    this.incomingEmailQuestion = new Parameters();
    this.incomingNameQuestion = new Parameters();
    this.incomingPhoneQuestion = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingPackages = new Parameters();
    this.outgoingResponse = new Parameters();
    this.outgoingParams = new Parameters();
    this.incomingInvitationCard = new Parameters();
    this.strings = new Parameters();
    this.recaptchaId = InvisibleReCaptchaComponent;
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingFormSettings.setAttributes({});
    this.incomingCompanyQuestion.setAttributes({});
    this.incomingPackages.setAttributes({});
    this.outgoingParams.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingQuestion.setAttributes({});
    this.incomingInvitationCard.setAttributes({});
    this.incomingNameQuestion.setAttributes({});
    this.incomingPhoneQuestion.setAttributes({});
    this.incomingEmailQuestion.setAttributes({});
    this.incomingInvitee.setAttributes({});
    this.incomingInvitee.setAttendeeName('');
    this.incomingInvitee.setAttendeeCompany('');
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.overflow = 'visible';
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.formId = this.activatedRoute.snapshot.params.formId;
    this.formId = this.service.decrypt(this.formId);
    if (this.incomingForm.hasAttribute(this.strings.getId())){
      // this.formValues = this.formBuilder.group({});
      this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
      this.incomingClient.setAttributes(this.incomingCampaign.getClient());
      this.clientService.setSelected(this.incomingClient.getAttributes());
      this.pg = this.service.flag;
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingFormSettings.setRedirectUrl(this.incomingFormSettings.hasAttribute(this.strings.getRedirectUrl()) ?
          this.incomingFormSettings.getRedirectUrl() : '');
      this.incomingFormSettings.setAfterRegisterSuccessfulMessage(
          this.incomingFormSettings.hasAttribute(this.strings.getAfterRegisterSuccessfulMessage()) ?
          this.incomingFormSettings.getAfterRegisterSuccessfulMessage() : '');
      this.service.backgroundColor = this.incomingFormSettings.getBackgroundColor();
      this.incomingForm.getControls().map((question: any) => {
        let incomingQuestion: any = new Parameters();
        incomingQuestion.setAttributes(question);
        // if(!incomingQuestion.hasAttribute(this.strings.getData())){
        //   incomingQuestion.setData('');
        //   incomingQuestion.setResult({});
        // }else incomingQuestion.setShow(1);
        incomingQuestion.setData('');
        incomingQuestion.setResult({});
        incomingQuestion.setShow(1);
        this.questions.push(incomingQuestion.getAttributes());
        if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName())
          this.incomingNameQuestion.setAttributes(incomingQuestion.getAttributes());
        if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany())
          this.incomingCompanyQuestion.setAttributes(incomingQuestion.getAttributes());
        if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePhone())
          this.incomingPhoneQuestion.setAttributes(incomingQuestion.getAttributes());
        if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeEmail())
          this.incomingEmailQuestion.setAttributes(incomingQuestion.getAttributes());
        if (incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationCard()) {
          this.incomingInvitationCard.setAttributes(incomingQuestion.getAttributes());
        }
      });

      this.updateResponseResults((result: any) => {
        this.questions.map((question: any) => {
          let incomingQuestion: any = new Parameters();
          incomingQuestion.setAttributes(question);
          incomingQuestion.getOptions().map((option: any) => {
            let incomingOption: any = new Parameters();
            incomingOption.setAttributes(option);
            if(incomingOption.hasAttribute(this.strings.getQuestions())){
              incomingOption.getQuestions().map((qs: any) => {
                let incomingQs: any = new Parameters();
                incomingQs.setAttributes(qs);
                this.hideQuestion(incomingQs.getAttributes(),
                    !this.service.isNull(incomingQuestion.getData()) &&
                    JSON.stringify(incomingQuestion.getResult()).includes(incomingOption.getCntrlNum()) ? 1 : 0);
              });
            }
          });
        });
        setTimeout(() => {
          if(this.incomingForm.hasAttribute(this.strings.getYScrolling())) {
            alert(this.incomingForm.getYScrolling());
            const elementId: any = document.getElementById(this.incomingForm.getYScrolling());
            elementId.scrollIntoView({behavior: 'smooth'});
          }
        });
      });
    }else {
      this.service.setLogo(null);
      this.requestForm((f: any) => {});
      // this.hasFormByStorage((form: any) => {
      //   if(this.service.isNull(form)){
      //     this.service.setLogo(null);
      //     this.requestForm((f: any) => {
      //       // if(!this.service.isNull(f)){
      //       //   alert(667777777);
      //       //   this.formService.setSelected(f);
      //       //   this.ngOnInit();
      //       // }
      //     });
      //   }else {
      //     this.incomingForm.setAttributes(form);
      //     this.ngOnInit();
      //   }
      // });
      // let url: any = window.location.href.split('?');
      // url = url.length === 2 ? url[1] : '';
      // this.router.navigateByUrl(
      //     this.strings.getSlash() +
      //     this.strings.getApp() +
      //     this.strings.getSlash() +
      //     this.strings.getEvents() +
      //     this.strings.getSlash() + this.service.encrypt(this.formId) + this.strings.getSlash() +
      //     this.strings.getAnnoucement() + '?' + url);
    }
    window.addEventListener('click', (e: any) => {});
  }

  updateResponseResults(call: any){
    this.storage.has(this.strings.getForm()).subscribe((status: any) => {
      if(status){
        this.storage.get(this.strings.getForm()).subscribe((form: any) => {
          let incomingForm: any = new Parameters();
          incomingForm.setAttributes(form);
          incomingForm.getControls().map((question: any) => {
            let incomingQuestion: any = new Parameters();
            incomingQuestion.setAttributes(question);
            if(incomingQuestion.hasAttribute(this.strings.getData())){
              this.questions.map((q: any) => {
                let incomingQ: any = new Parameters();
                incomingQ.setAttributes(q);
                if(parseFloat(incomingQuestion.getId()) === parseFloat(incomingQ.getId())){
                  incomingQ.setData(incomingQuestion.getData());
                  incomingQ.setResult(incomingQuestion.getResult());
                }
              });
            }
          }); call(null);
        });
      }else call(null);
    });
  }

  hasFormByStorage(call: any){
    this.storage.has(this.strings.getForm()).subscribe((form: any) => {
      if(form){
        this.storage.get(this.strings.getForm()).subscribe((f: any) => {
          let incomingForm: any = new Parameters();
          incomingForm.setAttributes(f);
          if(incomingForm.hasAttribute(this.strings.getId()) &&
              parseFloat(incomingForm.getId()) === parseFloat(this.formId)){
            call(incomingForm.getAttributes());
          }else call(null);
        });
      }else call(null);
    });
  }

  isShown(question: any){ return (question.hasOwnProperty(this.strings.getShow()) && this.service.parseFloat(question.show) === 1);}

  hideQuestion(question: any, status: any){
    let incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    this.questions.map((qs: any) => {
      let outgoingQuestion: any = new Parameters();
      outgoingQuestion.setAttributes(qs);
      if(parseFloat(outgoingQuestion.getId()) === parseFloat(incomingQuestion.getId())){
        outgoingQuestion.setShow(parseFloat(status));
        if(parseFloat(status) === 0){
          outgoingQuestion.setData(null);
          outgoingQuestion.setResult(null);
        }
        outgoingQuestion.getOptions().map((option: any) => {
          let incomingOption: any = new Parameters();
          incomingOption.setAttributes(option);
          incomingOption.getQuestions().map((qn: any) => {
            let incomingQuestionOption: any = new Parameters();
            incomingQuestionOption.setAttributes(qn);
            this.hideQuestion(incomingQuestionOption.getAttributes(),
                !this.service.isNull(outgoingQuestion.getData()) &&
                JSON.stringify(outgoingQuestion.getResult()).includes(incomingOption.getCntrlNum()) ? 1 : 0);
          });
        });
      }
    });
  }

  set(){
  }



  setQr(){
    setTimeout(() => {
      const element: any = document.getElementById('qrcodeId');
      if (element instanceof  HTMLElement){
        const config: Options = {
          height: 550,
          width: 550,
          shape: 'square',
          rotate: 0,
          data: this.strings.getHost() +
              this.strings.getSlash() +
              this.strings.getAppForms() +
              this.strings.getSlash() +
              this.service.encrypt(67 +
                  this.strings.getSlash() +
                  this.strings.getConfirmation() +
                  this.strings.getAccess() + 78 +
                  this.strings.getExtension() + this.incomingFormSettings.getBackgroundColor()).toString(),
          margin: 0,
          dotsOptions: {
            color: this.incomingFormSettings.getBackgroundColor(),
            type: 'extra-rounded'
          },
          cornersDotOptions: {
            color: this.incomingFormSettings.getBackgroundColor(),
            type: 'dot'
          },
          frameOptions: {
            style: 'FE_125',
            height: 250,
            width: 190,
            x: 140,
            y: 120,
            containers: [{
              fill: '#FFF',
              stroke: this.incomingFormSettings.getBackgroundColor()
            }, {
              fill: '#fff'
            }]
          },
          qrOptions: {
            errorCorrectionLevel: 'H',
          },
          backgroundOptions: {
            color: '#fff',
          },
          imageOptions: {
            crossOrigin: 'anonymous',
            margin: 0
          }
        };
        this.qrcode.create(config, element).subscribe((res) => {
          setTimeout((e) => {
            const contentId: any = document.getElementById('cardId');
            html2canvas(contentId).then(canvas => {
              this.zone.run(() => {
                this.pdfUrl = canvas.toDataURL('image/png');
                setTimeout(() => {
                  // this.getCard($incomingResponseResult.getAttributes());
                });
              });
            });
          }, this.service.timeout());
        });
      }
    }, this.service.timeout());
  }

  save(call: any){
    if(this.incomingForm.getAttributes().hasOwnProperty(this.strings.getId())) {
      this.incomingForm.setControls(this.questions);
      this.storage.set(this.strings.getForm(), this.incomingForm.getAttributes()).subscribe(() => {});
    }call(null);
  }

  ngOnDestroy(): void {
    // document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgb(16,30,81)';
    if(this.incomingForm.getAttributes().hasOwnProperty(this.strings.getId())) {
      this.incomingForm.setControls(this.questions);
      this.storage.set(this.strings.getForm(), this.incomingForm.getAttributes()).subscribe(() => {});
      setTimeout(() => {
        if(window.location.href.includes(this.strings.getWait()) &&
            this.status === 0)
          window.history.back();
      });
    }
    let output: any = this.service.getNavigatedUrl().toString().split('?');
    if(output.length !== 1){
      output = output[1].toString().split('&');
      output.map((split: any) => {
        if(split.toString().toLowerCase().includes('extension=')){
          document.getElementsByTagName( 'body')[0].style.backgroundColor =
              split.toString().split('=')[1];
        }else if(split.toString().toLowerCase().includes('access=')){
          this.service.logo = null;
          if(!this.service.app.hasOwnProperty(this.strings.getSettings())){
            this.service.setLogo(this.service.clientImgUrl() + split.toString().split('=')[1]);
            this.service.setLogo(this.service.getClientImgPart()  + split.toString().split('=')[1]);
          }else this.service.setLogo(this.service.getClientImgPart()  + split.toString().split('=')[1]);
        }
      });
    }

  }



  handleReset(){
  }

  handleLoad(){
  }

  handleSuccess(event: any){
    this.recaptcha = event;
  }

  handleExpire(){
    this.recaptchaId.reloadCaptcha();
    this.recaptcha = '';
  }

  onChecked(question: any, option: any, HTMLCheck: any){
    let incomingQuestion: any = new Parameters();
    let incomingOption: any = new Parameters();
    let incomingResult: any = new Parameters();
    incomingQuestion.setAttributes(question);
    if(parseFloat(incomingQuestion.getControlId()) === 5) {
      incomingQuestion.setData('');
      incomingQuestion.setResult({});
    }
    incomingOption.setAttributes(option);
    incomingResult.setAttributes(incomingQuestion.getResult());
    if (HTMLCheck.checked){
      incomingResult.setUnknown(incomingOption.getCntrlNum(), {label : incomingOption.getLabel()});
    }else {
      if (incomingResult.getAttributes().hasOwnProperty(incomingOption.getCntrlNum()))
        delete incomingResult.getAttributes()[incomingOption.getCntrlNum()];
    }
    incomingQuestion.setData('');
    for (const key in incomingResult.getAttributes()) {
      let l: any = Object.keys(incomingResult.getAttributes()).indexOf(key);
      const incomingLabel: any = new Parameters();
      incomingLabel.setAttributes(incomingResult.getAttributes()[key]);
      incomingQuestion.setData(incomingQuestion.getData() + (l === 0 ? '' : ':') +
          incomingLabel.getAttributes()[this.strings.getLabel()]);
    }
    incomingQuestion.getOptions().map((opt: any) => {
      let incomingOpt: any = new Parameters();
      incomingOpt.setAttributes(opt);
      if(parseFloat(incomingOpt.getId()) === parseFloat(incomingOption.getId())){
        incomingOpt.getQuestions().map((qs: any) => {
          let incomingQs: any = new Parameters();
          incomingQs.setAttributes(qs);
          this.hideQuestion(incomingQs.getAttributes(), 1);
        });
      }else {
        incomingOpt.getQuestions().map((qs: any) => {
          let incomingQs: any = new Parameters();
          incomingQs.setAttributes(qs);
          this.hideQuestion(incomingQs.getAttributes(), 0);
        });
      }
    });
    setTimeout(() => {
      const elementId: any = document.getElementById(incomingQuestion.getIndex());
      elementId.scrollIntoView({behavior: 'smooth'});
      this.save(() => {});
    });
  }

  onInputTextChange(question: any, $event: any){
    const incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setData($event.target.value);
    this.save(() => {});
  }

  isChecked(question: any, element: any, option: any){
    let result: any = false;
    let incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    if(incomingQuestion.hasAttribute(this.strings.getResult()) &&
        !this.service.isNull(incomingQuestion.getResult()) &&
        JSON.stringify(incomingQuestion.getResult()).includes( option[this.strings.getCntrlNum()]))
      result = true;
    return result;
  }

  validate(call: any){
    const incomingQuestionValidate: any = new Parameters();
    incomingQuestionValidate.setAttributes({});
    this.questions.map((question: any) => {
      const incomingResponseResult = new Parameters();
      const incomingQuestionSettings = new Parameters();
      incomingResponseResult.setAttributes(question);
      incomingQuestionSettings.setAttributes(incomingResponseResult.getSettings());
      incomingResponseResult.setRequired(
          incomingResponseResult.hasAttribute(this.strings.getRequired()) &&
          !this.service.isNull(incomingResponseResult.getRequired()) ? incomingResponseResult.getRequired() : 0);
      if (parseFloat(incomingResponseResult.getRequired()) !== 0 &&
          this.service.isNull(incomingResponseResult.getData()) && parseFloat(incomingResponseResult.getShow()) === 1){
        if (!incomingQuestionValidate.hasAttribute(this.strings.getId())) {
          incomingQuestionValidate.setAttributes(incomingResponseResult.getAttributes());
        }
      }
    });
    if (incomingQuestionValidate.hasAttribute(this.strings.getId())) {
      this.notifier.notify(this.strings.getSuccess(), incomingQuestionValidate.getLabel() + ' ' + this.strings.getRequired());
      const elementId: any = document.getElementById(incomingQuestionValidate.getIndex());
      elementId.scrollIntoView({behavior: 'smooth'});
    }call(incomingQuestionValidate.hasAttribute(this.strings.getId()) ? 1 : 0);
  }

  calTotal(){
    let bill: any = null;
    let tickets: any = null;
    let invoice: any = null;
    if(this.incomingForm.getAttributes().hasOwnProperty(this.strings.getId())){
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingForm.getControls().map((question: any) => {
        let incomingQuestion: any = new Parameters();
        incomingQuestion.setAttributes(question);
        if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getTickets()){
          if(!this.service.isNull(incomingQuestion.getData())) {
            tickets = parseFloat(incomingQuestion.getData());
            bill = this.service.isNull(bill) ?
                parseFloat(incomingQuestion.getData()) :
                (bill * parseFloat(incomingQuestion.getData()));
          }

        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvoice()){
          invoice = incomingQuestion.getData();
        }else if(incomingQuestion.getCntrlNum() === this.incomingFormSettings.getPackages()){
          incomingQuestion.getOptions().map((option: any) => {
            let incomingOption: any = new Parameters();
            incomingOption.setAttributes(option);
            let incomingOptionSettings: any = new Parameters();
            incomingOptionSettings.setAttributes(incomingOption.getSettings());
            if(JSON.stringify(incomingQuestion.getResult()).includes(incomingOption.getCntrlNum())){
              if(incomingOptionSettings.hasAttribute(this.strings.getSellingPrice()) &&
                  !this.service.isNull(incomingOptionSettings.getSellingPrice())){
                bill = this.service.isNull(invoice) ? parseFloat(incomingOptionSettings.getSellingPrice()) : (bill * parseFloat(incomingOptionSettings.getSellingPrice()));
              }
            }
          });
        }
      });
    }
  }


  ngSubmit(formValues: any){
    // if(this.incomingForm.hasAttribute(this.strings.getIsPosted()) && parseFloat(this.incomingForm.getIsPosted()) === 1) {
    //   window.location.replace(this.incomingFormSettings.getRedirectUrl());return;
    // }
    this.validate((result: any) => {
      if(parseFloat(result) === 0){
        // if(this.service.isNull(this.recaptcha)){
        //   this.notifier.notify(this.strings.getSuccess(),
        //       this.strings.getRecaptchaValidationText()); return;
        //   // window.scrollTo(0, this.service.getHeight());
        // }
        this.responseService.setPost(this.incomingForm.getAttributes(), (response) => {
          this.outgoingResponse.setAttributes(response);
          const questions: any = [];
          this.questions.map((question: any) => {
            const $incomingQuestion: any = new Parameters();
            $incomingQuestion.setAttributes(Object.assign({}, question));
            $incomingQuestion.setResult($incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull($incomingQuestion.getResult()) ?
                JSON.stringify($incomingQuestion.getResult()) : '');
            $incomingQuestion.getAttributes()[this.strings.getFormControlId()] =
                $incomingQuestion.getAttributes().control.id;
            $incomingQuestion.attributes = Object.assign($incomingQuestion.getAttributes(), {
              data: this.service.isNull($incomingQuestion.getAttributes().data) ? '' :
                  $incomingQuestion.getAttributes().data});
            delete $incomingQuestion.getAttributes().options;
            questions.push($incomingQuestion.getAttributes());
          });
          this.outgoingResponse.setControls(questions);
          const posts: any = [];
          posts.push(this.outgoingResponse.getAttributes());
          this.status = 1;
          this.router.navigateByUrl(this.strings.getWait());
          this.post(posts, () => {
            this.status = parseFloat('0');
            if(!this.service.isNull(this.incomingFormSettings.getPackages())){
              this.incomingForm.setControls(this.questions);
              this.formService.setSelected(this.incomingForm.getAttributes());
              this.notifier.notify(this.strings.getSuccess(), this.service.app.strings.params.msgrsp);
              setTimeout(() => {
                window.location.replace(this.incomingFormSettings.getRedirectUrl());
              }, this.service.timeout() * parseFloat('6'));
              // this.router.navigateByUrl(
              //     this.strings.getSlash() +
              //     this.strings.getApp() +
              //     this.strings.getSlash() + this.strings.getEvents() +
              //     this.strings.getSlash() +
              //     this.service.encrypt(this.outgoingResponse.getPostId()) +
              //     this.strings.getSlash() +
              //     this.strings.getAccounts() +
              //     this.strings.getSlash() +
              //     this.strings.getDetails());
            }else{
              if(this.incomingInvitationCard.getAttributes().hasOwnProperty(this.strings.getData())){
                if (this.incomingPhoneQuestion.getAttributes().hasOwnProperty(this.strings.getData()) &&
                    !this.service.isNull(this.incomingPhoneQuestion.getData())) {
                  this.outgoingRequest.setAttributes({});
                  this.outgoingRequest.setAttendeePhone(this.incomingPhoneQuestion.getData());
                  this.outgoingRequest.setFlag(this.strings.getWhatsapp());
                  this.outgoingRequest.setFormId(this.incomingForm.getId());
                  this.outgoingRequest.setResponseCntrlNum(this.outgoingResponse.getPostId());
                  this.sendInvitationCard(this.outgoingRequest.getAttributes(), (rs: any) => {
                    setTimeout(() => {
                      this.notifier.notify(this.strings.getSuccess(),
                          this.service.app.strings.invitation.card.sent);
                      setTimeout(() => {
                        window.location.replace(this.service.isNull(this.incomingFormSettings.getRedirectUrl()) ? 'https://realinteractiv.co.tz' : this.incomingFormSettings.getRedirectUrl());
                      }, this.service.timeout() * 12);
                    });
                    if (this.incomingEmailQuestion.getAttributes().hasOwnProperty(this.strings.getData()) &&
                        !this.service.isNull(this.incomingEmailQuestion.getData())) {
                      this.outgoingRequest.setFlag(this.strings.getEmail());
                      this.sendInvitationCard(this.outgoingRequest.getAttributes(), (rs: any) => {});
                    }
                  });
                }
              }else {
                this.notifier.notify(this.strings.getSuccess(),
                    this.service.isNull(this.incomingFormSettings.getAfterRegisterSuccessfulMessage()) ?
                        this.strings.getWillTouch() : this.incomingFormSettings.getAfterRegisterSuccessfulMessage());
                setTimeout(() => {
                  window.location.replace(this.incomingFormSettings.getRedirectUrl());
                }, this.service.timeout()*12);
              }
            }
          });
        });
      }
    });
  }

  requestForm(call: any){
    if (!this.formService.getSelected().hasOwnProperty(this.strings.getId())){
      this.router.navigateByUrl(this.strings.getWait());
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setId(this.formId);
      this.service.httpService('POST',
          this.service.app.routes.forms.get.toString(),
          this.outgoingRequest.getAttributes(), { ignoreLoadingBar : 'true', notify: false}, (result: any) => {
            this.formService.setSelected(result);
            this.incomingForm.setAttributes(result);
            call(this.incomingForm.getAttributes());
            window.history.back();
            // this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
          }, (error: any) =>  {
            this.requestForm(call);
          });
    }else call(null);
  }

  sendInvitationCard(request: any, callback: any){
    this.outgoingRequest.setAttributes(request);
    this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (result: any) => {callback();
        }, (error: any) => { callback(); });
  }
  download(call: any){
    this.imgService.download(this.incomingForm.getName(), this.canvas, (result: any) => {call(null);});
  }

  post(posts: any, call: any){
    this.service.httpService(
        'POST',
        this.service.app.routes.responses.post,
        {posts: JSON.stringify(posts)},
        {ignoreLoadingBar : 'true', notify: 'false'},
        (result: any) => {
          this.incomingForm.setIsPosted(1);
          call(null);
        }, (error: any) => {
          this.post(posts, call);
        });
  }

  createCard(){
    setTimeout(() => {
      if (this.incomingInvitationCard.hasAttribute(this.strings.getLabel())) {
        this.service.getDataUrl(this.incomingFormSettings.getFrame(), (url: any) => {
          if (!this.service.isNull(url)) {
            this.incomingInvitationCard.setLabel(this.incomingInvitationCard.getLabel()
                .replaceAll(this.incomingFormSettings.getFrame(), url)
                .replaceAll('*' + this.strings.getName(), this.incomingNameQuestion.hasAttribute(this.strings.getData()) ? this.incomingNameQuestion.getData() : '')
                .replaceAll('*' + this.strings.getCompany(), this.incomingCompanyQuestion.hasAttribute(this.strings.getData()) ? this.incomingCompanyQuestion.getData() : ''));
            setTimeout(() => {
              const element: any = document.getElementById('qrcodeId');
              if (element instanceof  HTMLElement){
                const config: Options = {
                  height: 550,
                  width: 550,
                  shape: 'square',
                  rotate: 0,
                  data: this.strings.getHost() +
                      this.strings.getSlash() +
                      this.strings.getAppForms() +
                      this.strings.getSlash() +
                      this.service.encrypt(this.outgoingResponse.getPostId() +
                          this.strings.getSlash() +
                          this.strings.getConfirmation() +
                          this.strings.getAccess() + this.incomingClient.getLogo().split(this.strings.getClients() + this.strings.getSlash())[1] +
                          this.strings.getExtension() + this.incomingFormSettings.getBackgroundColor()).toString(),
                  margin: 0,
                  dotsOptions: {
                    color: this.incomingFormSettings.getBackgroundColor(),
                    type: 'extra-rounded'
                  },
                  cornersDotOptions: {
                    color: this.incomingFormSettings.getBackgroundColor(),
                    type: 'dot'
                  },
                  frameOptions: {
                    style: 'FE_125',
                    height: 250,
                    width: 190,
                    x: 140,
                    y: 120,
                    containers: [{
                      fill: '#FFF',
                      stroke: this.incomingFormSettings.getBackgroundColor()
                    }, {
                      fill: '#fff'
                    }]
                  },
                  qrOptions: {
                    errorCorrectionLevel: 'H',
                  },
                  backgroundOptions: {
                    color: '#fff',
                  },
                  imageOptions: {
                    crossOrigin: 'anonymous',
                    margin: 0
                  }
                };
                this.qrcode.create(config, element).subscribe((res) => {
                  setTimeout((e) => {
                    const contentId: any = document.getElementById('cardId');
                    html2canvas(contentId).then(canvas => {
                      this.zone.run(() => {
                        this.canvas = canvas.toDataURL('image/png');
                        setTimeout(() => {
                          this.download(() => {
                            setTimeout(() => {

                            });
                          });
                        });
                      });
                    });
                  }, this.service.timeout());
                });
              }
            }, this.service.timeout() * 2);
          }
        });
      }
    }, this.service.timeout());
  }

  moveTo(){
    let url: any = window.location.href.split('?');
    url = url.length === 2 ? url[1] : '';
    this.router.navigateByUrl(
        this.strings.getSlash() +
        this.strings.getApp() +
        this.strings.getSlash() +
        this.strings.getEvents() +
        this.strings.getSlash() + this.service.encrypt(this.formId) + this.strings.getSlash() +
        this.strings.getAnnoucement() + '?' + url);
  }

}
