<div>
    <ul class="mdl-list">
        <li class="mdl-list__item" *ngFor="let question of incomingOption.getQuestions()">
            <center>
                <div class="sawasdee20 textBlueColor" [ngStyle]="{}">{{question.label}}</div><br><br>
                <div>
                    <ng-container *ngIf="question.control.id === 5; else number">
                        <mat-radio-group
                                aria-labelledby="example-radio-group-label"
                                class="example-radio-group">
                            <mat-radio-button
                                    class="example-radio-buttonn"
                                    #checkbox
                                    *ngFor="let option of question.options"
                                    [value]="option">
                                <a class="sawasdee20 textBlueColor" [ngStyle]="{}">{{service.setSmallCharacters(option.label.toString())}}</a>
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-container>
                    <ng-template #number>
                        <ng-container *ngIf="question.control.id === 10; else description">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="text"
                                       class="sawasdee18 textBlueColor"
                                       autocomplete="off"
                                       placeholder="{{question.placeholder}}"
                                       matInput
                                       #numberId
                                       [ngStyle]="{'text-align': 'center'}"
                                       [(ngModel)]="question.data">
                            </mat-form-field>
                        </ng-container>
                    </ng-template>
                    <ng-template #description>
                        dfdbkjfdfjdfd
                    </ng-template>
                </div>
            </center>
        </li>
    </ul>
</div>