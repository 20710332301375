<ng-container *ngIf="!service.isNull(service.logo)">
    <div class="content-center" [ngStyle]="{'width':(this.service.getWidth()-20)+'px','height':(this.service.getHeight()-20)+'px','background':'transparent'}">
        <div class="circle mdl-shadow--3dp content-center"
             [ngStyle]="{'width':'150px','height':'150px','border-width':'0px','animation' : 'mymove 5s infinite'}">
            <img  class="logox"
                  src="{{service.logo}}"
                  (error)="onerrorHandle($event)"
                  alt=""/>
        </div>
    </div>
</ng-container>
