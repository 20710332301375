import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";
import {FormService} from '../../../form.service';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {DistributorService} from "../../../../distributors/distributor.service";
@Component({
    selector: 'app-text-preview-controls',
    templateUrl: './text-preview-controls.component.html',
    styleUrls: ['./text-preview-controls.component.scss']
})
export class TextPreviewControlsComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('element', {static: false})  element: ElementRef;
    @ViewChild('matAutocompleteTrigger', {static: false}) matAutocompleteTrigger: any;
    @Input() question: any;
    @Input() position: any;
    label: any;
    incomingSettings: any;
    incomingQuestion: any;
    result: any = true;
    suggestionControl = new FormControl();
    distributorControl = new FormControl();
    filteredSuggestions: any;
    filteredDistributors: any;
    distributors: any = [];
    strings: any;
    constructor(public service: AppService,
                private broadcastChannel: BroadcastChannelService,
                private distributorService: DistributorService,
                public formService: FormService) {
        this.incomingQuestion = new Parameters();
        this.incomingSettings = new Parameters();
        this.strings = new Parameters();
        this.incomingSettings.setAttributes({});
        this.matAutocompleteTrigger = MatAutocompleteTrigger;
        this.strings.setAttributes({});
    }

    ngOnInit(): void {
        this.ngOnChanges({});
    }

    onSuggestionSelectionChanged(event: any){
        this.element.nativeElement.value  = event.option.value.description;
        this.incomingQuestion.setData(event.option.value.description);
        this.suggestionControl.setValue(event.option.value);
        this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.next});
    }

    onDustributorSelected(event: any){
        console.log(event.option.value);
        this.element.nativeElement.value  = event.option.value.name;
        this.incomingQuestion.setData(event.option.value.name);
        this.incomingQuestion.setResult(event.option.value);
        this.distributorControl.setValue(event.option.value);
        this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.next});
    }

    getSuggestions(event: any){
        setTimeout(() => {
            this.service.getSuggestions(event.target.value, () => {
                if(this.service.isNull(event.target.value))
                    this.startSuggestions();
            });
        });
    }

    onMouseEnter(inputText: any){
        this.incomingQuestion.setData(inputText);
        this.incomingQuestion.setResult('');
        if(this.service.isNull(inputText)){
            if(this.incomingSettings.attributes.hasOwnProperty('_default')){
                if(!this.service.isNull(this.incomingSettings.getDefault())){
                    this.incomingQuestion.setData(this.incomingSettings.getDefault());
                }
            }
        }
        setTimeout(() => {this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.next});});
    }

    addSuggestion(event: any){
        this.service.setSuggestion(event.target.value, (result: any) => {});
    }

    displayFn(value: any){
        return value.description;
    }

    distributorDisplayFn(value: any){
        return value;
    }

    startSuggestions(){
        this.filteredSuggestions = this.suggestionControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.description),
            map(name => name ? this._filterSuggestion(name) : this.service.app.data.suggestions.slice())
        );
    }

    private _filterSuggestion(name: string) {
        const filterValue: any = name.toString().toLowerCase();
        return this.service.app.data.suggestions.filter((option: any) => option.description.toString().toLowerCase().includes(filterValue));
    }

    private _filterDistributor(name: string) {
        let args: any = name.toString().toLowerCase();
        return this.distributors.filter((option: any) => JSON.stringify(option).toString().toLowerCase().includes(args));
    }

    ngOnChanges(changes: any): void {
        setTimeout(() => {
            this.service.app.data = Object.assign(this.service.app.data, {suggestions: this.service.app.data.hasOwnProperty('suggestions') ?  this.service.app.data.suggestions : []});
            this.service.setStrings((res: any) => {
                this.strings.setAttributes(this.service.app.strings.params);
                this.incomingQuestion.setAttributes(this.question);
                this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
                this.incomingQuestion.setData(this.service.isNull(this.incomingQuestion.getData()) &&
                (this.incomingSettings.attributes.hasOwnProperty('_default') &&
                    !this.service.isNull(this.incomingSettings.getDefault())) ?
                    this.incomingSettings.getDefault() :
                    this.incomingQuestion.getData());
                this.startSuggestions();
                this.ngAfterViewInit();
                setTimeout((suggestions: any) => {
                    this.element.nativeElement.value = this.incomingQuestion.getData();
                    suggestions = this.service.app.data.suggestions.filter((suggestion: any) => {
                        return suggestion.description.toString().toLowerCase().includes(this.incomingQuestion.getData().toString().toLowerCase());});
                    this.matAutocompleteTrigger.closePanel();
                });
                if(!this.service.isNull(this.incomingQuestion.getData())){
                    // this.element.nativeElement.value = this.incomingQuestion.getData();
                }
            });
        });
    }

    setDistributorSuggestions(){
        this.filteredDistributors = this.distributorControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterDistributor(name) : this.distributors.slice())
        );
    }

    onDistributorInputTextChange(event: any){
        setTimeout(() => {
            this.incomingQuestion.setAttributes(this.question);
            this.incomingQuestion.setData(event.target.value);
            this.incomingQuestion.setResult('');
            if(!this.service.isNull(event.target.value)){
                this.distributors = [];
                this.distributorService.search(event.target.value, (distributors: any) => {
                    if(distributors !== null){
                        distributors.map((distributor: any) => {
                            let incomingDistributor: any = new Parameters();
                            incomingDistributor.setAttributes(distributor);
                            let position: any = this.service.findIntegerIndex(this.distributors,
                                this.strings.getId(),
                                incomingDistributor.getId());
                            if(position === -1) this.distributors.push(incomingDistributor.getAttributes());
                        });
                    } this.setDistributorSuggestions();
                });
            }
        });
    }

    onInputTextChange(event: any){
        this.incomingQuestion.setAttributes(this.question);
        this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
        this.incomingQuestion.setData(event.target.value);
        if(this.service.isNull(event.target.value)){
            if(this.incomingSettings.attributes.hasOwnProperty('_default')){
                if(!this.service.isNull(this.incomingSettings.getDefault())){
                    this.incomingQuestion.setData(this.incomingSettings.getDefault());
                }
            }
        }
    }

    validateTextLength(event: any){
        var e = event || window.event;
        var key = e.keyCode || e.which;
        if(key === 8){
        }else {
            if(!this.service.isNull(event.target.value)){
                this.incomingQuestion.setAttributes(this.question);
                this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
                if(this.incomingSettings.attributes.hasOwnProperty('text_size')){
                    if(!this.service.isNull(this.incomingSettings.attributes.text_size)){
                        if(parseFloat(this.incomingSettings.attributes.text_size) < event.target.value.toString().length){
                            // input is INVALID
                            e.returnValue = false;
                            if (e.preventDefault)
                                e.preventDefault();
                        }
                    }
                }
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() =>{
            this.matAutocompleteTrigger.closePanel();
            if(this.element.nativeElement){
                this.element.nativeElement.focus();
            }
        });
    }

}


