import { Component, OnInit,AfterViewInit, Inject, ViewChild } from '@angular/core';
import {AppService} from '../../../app.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../../parameters';
import * as $ from 'jquery';
import {CarouselComponent} from 'angular-responsive-carousel';
import {newArray} from "@angular/compiler/src/util";
@Component({
  selector: 'app-dialog-view-image',
  templateUrl: './dialog-view-image.component.html',
  styleUrls: ['./dialog-view-image.component.scss']
})
export class DialogViewImageComponent implements OnInit,AfterViewInit {
  @ViewChild("myCarousel") myCarousel : CarouselComponent;
  incomingResponseResult: any;
  showCarousel: any = false;
  selectedImgIndex = 0;
  nextSlide: any = null;
  responses: any = [];
  responses2: any = [];
  incomingResponse: any;
  position: any = 0;
  incremental: any = 0;
  selectedTabIndex : any = 0;
  carouselRef : any = 0;
  showImg = true;
  width: any = 0;
  height: any = 0;
  strings: any;
  imageSelected: any = null;
  images: any;
  constructor(
    public  service: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogViewImageComponent>) {
    this.incomingResponseResult = new Parameters();
    this.incomingResponse = new Parameters();
    this.strings = new Parameters();
    // this.incomingResponseResult.setAttributes(this.data.data);
    this.strings.setAttributes(this.service.app.strings.params);
    this.images = this.data.images.filter(image => Object.assign({}, image));
    this.imageSelected = Object.assign({}, this.data.image);
  }

  ngOnInit(): void {
    let positinonArray: any = this.service.findIntegerIndex(this.data.images, this.strings.getId(), this.imageSelected.id);
    this.images.splice(positinonArray, 1);
    this.images.unshift(this.imageSelected);
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
       const matDialogContainers: any = document.getElementsByClassName( 'mat-dialog-container');
       for (var i = 0; i < matDialogContainers.length; i++) {
         const matDialogContainer: any = matDialogContainers[i];
         if (matDialogContainer instanceof HTMLElement) {
           matDialogContainer.style.backgroundColor = '#000';
           matDialogContainer.style.padding = '0px';
        }
       }
    });
  }

}
