<div>
    <div class="page" [ngStyle]="{'height':this.service.getHeight()+'px'}">
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="">
                    <div class="fixed">
                        <div class="roww">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="row top_tiles" [ngStyle]="{'padding': '2px'}">
                                    <div [ngStyle]="{'width':this.service.getWidth()+'px'}">
                                        <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">
                                            <ng-container *ngFor="let image of (this.images |slice:0:300| jsonFilter:this.service.searchText);let incrementalResponse=index">
                                                <mat-grid-tile (click)="view(image)">
                                                    <div class="cursor demo-card-image mdl-card mdl-shadow--3dp"
                                                         style="animation: deg20 5s infinite;background:url({{image.data}}) center / cover">
                                                    </div>
                                                </mat-grid-tile>
                                            </ng-container>
                                        </mat-grid-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
