import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Parameters} from "../../../../parameters";
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-checkbox-dialog',
  templateUrl: './checkbox-dialog.component.html',
  styleUrls: ['./checkbox-dialog.component.scss']
})
export class CheckboxDialogComponent implements OnInit {
  incomingOption: any;
  constructor(
      public service: AppService,



















































































































































































































































































      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<CheckboxDialogComponent>
  ) {
    this.incomingOption = new Parameters();
  }

  ngOnInit(): void {
    this.incomingOption.setAttributes(this.data.params.option);
    alert(this.incomingOption.getLabel());
  }

}
