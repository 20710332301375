import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormService} from "../../../form.service";
import {Parameters} from "../../../../parameters";
import {AppService} from "../../../../app.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";

@Component({
    selector: 'app-label-create-control',
    templateUrl: './label-create-control.component.html',
    styleUrls: ['./label-create-control.component.scss']
})
export class LabelCreateControlComponent implements OnInit, OnChanges {
    @Input() question: any;
    incomingForm: any;
    incomingSettings: any;
    color: any = '#5A738E';
    incomingQuestion: any;
    incomingQuestionSettings: any;
    strings: any;
    constructor(public formService: FormService,
                private broadcastChannel: BroadcastChannelService,
                public service: AppService) {
        this.incomingForm = new Parameters();
        this.incomingSettings = new Parameters();
        this.incomingQuestion = new Parameters();
        this.strings = new Parameters();
        this.incomingQuestionSettings = new Parameters();
        this.incomingForm.setAttributes(this.formService.selected);
        this.incomingSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingQuestionSettings.setAttributes({});
        this.color = this.incomingSettings.attributes.hasOwnProperty('color') ? this.incomingSettings.getColor() : this.color;
        this.strings.setAttributes(this.service.app.strings.params);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.incomingQuestion.setAttributes(this.question);
        this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
        this.incomingQuestionSettings.setHidden(this.incomingQuestionSettings.getAttributes().hasOwnProperty('hidden') ? this.incomingQuestionSettings.getHidden() : 0);
        this.incomingQuestionSettings.setSrc(this.incomingQuestionSettings.getAttributes().hasOwnProperty('src') ? this.incomingQuestionSettings.getSrc() : '');
        if(parseFloat(this.incomingQuestionSettings.getHidden()) === 1){
            if(this.service.navButton === 1 || this.service.navButton === 0){
                this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.next});
            }else if(this.service.navButton === -1){
                this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.rollback});
            }
        }
    }

  ngOnInit(): void {
  }

  scrollingPart(){
        return document.body.scrollHeight || document.documentElement.scrollHeight;
  }

}
