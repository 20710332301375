<!--<app-map></app-map>-->
<ng-container *ngIf="this.orders.length !== 0">
    <button mat-fab
            [fxHide.lg]="true"
            [fxHide.md]="true"
            [fxHide.xl]="true"
            class="defaultColor2"
            [ngStyle]="{'position':'absolute','top':'10px','right':'10px','z-index': '999999999'}">
        <mat-icon>reorder</mat-icon>
    </button>
    <div class="col-lg-3 col-md-3 col-sm-5 col-xs-12"
         [ngStyle]="{'position':'absolute','top':'5px','right':'5px','background-color':'transparent','z-index': '999999999'}">
        <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}"
             [fxHide.sm]="true"
             [fxHide.xs]="true"
             class="mdl-shadow--3dp">
            <table [ngStyle]="{'width':'100%','margin':'5px'}">
                <td valign="middle" class="cursor">
                    <mat-icon matSuffix>reorder</mat-icon>
                </td>
                <td valign="middle" [ngStyle]="{'width':'100%'}">
                    <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','padding':'5px','border-width':'0px','width':'100%'}"
                            autocomplete="off"
                            ngClass="sawasdee18 searchbar"
                            #addressId
                            placeholder=""
                            (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                            (onLocationSelected)="onLocationSelected($event)"
                            matGoogleMapsAutocomplete>
                </td>
                <td valign="middle" class="cursor">
                    <mat-icon matSuffix >location_searching</mat-icon>
                </td>
            </table>
        </div>
    </div>
<!--    <table [ngStyle]="{'position':'absolute','top':'5xp','right':'5px','background-color':'transparent','width':'100%','z-index': '999999999'}">-->
<!--        <tr>-->
<!--            <td valign="top" >-->
<!--                <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mdl-shadow&#45;&#45;2dp">-->
<!--                    <table [ngStyle]="{'width':'100%','margin':'5px'}">-->
<!--                        <td valign="middle" class="cursor">-->
<!--                            <mat-icon matSuffix>reorder</mat-icon>-->
<!--                        </td>-->
<!--                        <td valign="middle" [ngStyle]="{'width':'100%'}">-->
<!--                            <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
<!--                                    autocomplete="off"-->
<!--                                    ngClass="sawasdee18 searchbar"-->
<!--                                    #addressId-->
<!--                                    placeholder=""-->
<!--                                    matGoogleMapsAutocomplete>-->
<!--                        </td>-->
<!--                        <td valign="middle" class="cursor">-->
<!--                            <mat-icon matSuffix >location_searching</mat-icon>-->
<!--                        </td>-->
<!--                    </table>-->
<!--                </div>-->
<!--            </td>-->
<!--        </tr>-->
<!--    </table>-->
    <agm-map
        #agm
        [latitude]="this.service.parseFloat(currentlat)"
        [longitude]="this.service.parseFloat(currentlng)"
        [fitBounds]="false"
        [zoom]="zoom"
        [disableDefaultUI]="true"
        [styles]="this.service.app.settings.map.styles.style1"
        (mapClick)="onmapClick()"
        [ngStyle]="{'height': this.service.getHeight() + 'px', 'width':this.service.getWidth() + 'px'}"
        *ngIf="!this.service.isNull(currentlat)">
<!--    <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>-->
<!--    <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>-->
        <agm-marker
                    [latitude]="this.service.parseFloat(this.dirlat)"
                    [longitude]="this.service.parseFloat(this.dirlng)"
                    [agmFitBounds]="true"
                    [openInfoWindow]="true"
                    [iconUrl]="this.pin"
                    *ngIf="this.service.parseFloat(currentlng) !== 0">
        </agm-marker>
        <agm-circle [latitude]="this.service.parseFloat(this.currentlat)"
                    [longitude]="this.service.parseFloat(this.currentlng)"
                    [radius]="5000"
                    [fillColor]="'red'"
                    [circleDraggable]="false"
                    [editable]="false"
                    (radiusChange)="radiusChange()">
        </agm-circle>
        <ng-container *ngFor="let order of this.orders">
<!--            <ng-container *ngFor="let route of order.routes">-->
<!--                <agm-marker-->
<!--                        [latitude]="service.parseFloat(route.origin.lat)"-->
<!--                        [longitude]="service.parseFloat(route.origin.lng)"-->
<!--                        [agmFitBounds]="true"-->
<!--                        (markerClick)="onMarkerClick(infoWindow)"-->
<!--                        [openInfoWindow]="true"-->
<!--                        [iconUrl]="this.pin">-->
<!--                    <agm-info-window #infoWindow>-->
<!--                        <div [ngStyle]="{'padding':'10px','font-family':'Sawasdee','-webkit-transform':' scale(1.15,0.77)','color':'rgb(90, 115, 142)'}">-->
<!--                            <div [innerHTML]="order.reference3.replace('THANK YOU !','') | safeUrl"></div>-->
<!--                            <div><center><button mat-raised-button-->
<!--                                                 (click)="addDelivery(order)"-->
<!--                                                 [ngStyle]="{'color':'rgb(90, 115, 142)'}">I have delivered</button>-->
<!--                            </center></div>-->
<!--                        </div>-->
<!--                    </agm-info-window>-->
<!--                </agm-marker>-->

                <agm-marker
                        [latitude]="this.service.parseFloat(order.lat)"
                        [longitude]="this.service.parseFloat(order.lng)"
                        [agmFitBounds]="true"
                        [openInfoWindow]="true"
                        (markerClick)="onmarkerClick(order,null)"
                        [iconUrl]="this.marker3">
                </agm-marker>
                <agm-direction
                        *ngIf="order.hasOwnProperty(strings.getShowDirection()) && order[strings.getShowDirection()] === 1"
                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"
                        [provideRouteAlternatives]="false"
                        [waypoints]="[]"
                        [origin]="{ lat: service.parseFloat(currentlat), lng: service.parseFloat(currentlng)}"
                        [destination]="{ lat: this.service.parseFloat(order.lat), lng: this.service.parseFloat(order.lng) }">
                </agm-direction>
            </ng-container>
<!--        </ng-container>-->
    </agm-map>
</ng-container>

