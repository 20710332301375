<cdk-virtual-scroll-viewport class="example-viewport">
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12"
         #container
         [ngStyle]="{'height': (this.service.getHeight()+this.yScrollingPart)+'px','margin':'0 0 0px 0px','background-repeat':'no repeat','padding':'0 0 0px 0px','display':'block', 'object-fit': 'contain','background-image':'url('+'assets/tpsf/images/chatbot2.jpg'+')'}">
        <mat-drawer-container class="example-container" [hasBackdrop]="true">
            <mat-drawer #drawer
                        [mode]="'push'"
                        [ngStyle]="{'width': container.clientWidth+'px','height': container.clientHeight+'px','z-index': '999999999'}">
                <agm-map
                    #gm
                    [latitude]="lat"
                    [longitude]="lng"
                    [fitBounds]="false"
                    [zoom]="zoom"
                    [disableDefaultUI]="true"
                    [styles]="service.app.settings.map.styles.style1"
                    [ngStyle]="{'height' : container.clientHeight + 'px','width':container.clientWidth+'px'}">
                    <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
                    <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
                    <agm-marker
                            [latitude]="currentLocationLat"
                            [longitude]="currentLocationLng"
                            [iconUrl]='iconUrl'>
                    </agm-marker>
                </agm-map>
                <div [ngStyle]="{'position': 'fixed','top':'20%','left':((container.clientWidth-350)/2)+'px'}">
                    <div [ngStyle]="{'border-radius': '25px','background':'#fff'}" class="mdl-shadow--2dp">
                        <div [ngStyle]="{'width':'100%'}">
                            <table [ngStyle]="{'width':'100%'}">
                                <td [ngStyle]="{'width':'100%'}">
                                    <input  [ngStyle]="{'color':'#000','text-align':'center','width':'350px'}"
                                            autocomplete="off"
                                            ngClass="searchbar"
                                            #addresselm
                                            [address]="addressValue"
                                            (input)="onInputTextChange($event)"
                                            (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                                            (onLocationSelected)="onLocationSelected($event)"
                                            matGoogleMapsAutocomplete
                                            placeholder="">
                                </td>
                                <td class="cursor">
                                    <mat-icon matSuffix
                                              (click)="addGooglelocation(addresselm.value)"
                                              [ngStyle]="{'color':this.incomingFormSettings.getBackgroundColor()}">send</mat-icon>
                                </td>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <div class="container mdl-shadow--3dp"
                     [ngStyle]="{'width':'100%','height':'100%','background-repeat':'no repeat','display':'block', 'object-fit': 'cover','background-image':'url(assets/images/whatsapp/whatsapp-3.jpg)'}">
                    <table  [ngStyle]="{'height': (this.service.getHeight()+this.yScrollingPart)+'px','width':'100%'}">
                        <tr>
                            <td valign="top">
                                <ng-container *ngFor="let responseResult of outgoingResponseResults">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div ngClass="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                             [id]="responseResult.formControl.id"
                                             [ngStyle]="{'margin-bottom':'25px'}"
                                             [hidden]="responseResult.hasOwnProperty(strings.getUnvisible()) &&
                                         this.service.parseFloat(responseResult[this.strings.getUnvisible()]) === 1">
                                            <mat-card class="example-card pull-left"
                                                      [ngStyle]="{'max-width':'auto'}">
                                                <img mat-card-image
                                                     [src]="responseResult.formControl.settings.hasOwnProperty('src') &&
                                                     !this.service.isNull(responseResult.formControl.settings.src)  ?
                                                     responseResult.formControl.settings.src : responseResult.data"
                                                     alt="">
                                                <!--                                                <div ngClass="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
                                                <!--                                                    <i ngClass="material-icons pull-right cursor" [ngStyle]="{}"-->
                                                <!--                                                    (click)="flagforContextmenu = 0;incomingResponseResultSelected.setAttributes(responseResult);onContextMenu($event, responseResult)">expand_more</i>-->
                                                <!--                                                </div>-->
                                                <ng-container *ngIf="responseResult.formControl.settings.hasOwnProperty('src') &&
                                                     !this.service.isNull(responseResult.formControl.settings.src) &&
                                                     responseResult.formControl.settings.src.includes('.mp4')">
                                                    <video [autoplay]="true"
                                                           [height]="350"
                                                           [ngStyle]="{'width': '100%', 'height': '350px', 'display':'block', 'object-fit': 'cover'}"
                                                           class=""
                                                           [loop]="false">
                                                        <source [src]="'https://patten.co.tz/shamba_la_PAFYUMU.mp4'">
                                                    </video>
                                                </ng-container>
                                                <!-- {{responseResult.getUnknown('formControl').hasOwnPropety()}}-->
                                                <!--                    <mat-card-header>-->
                                                <!--                        <div mat-card-avatar class="example-header-image"></div>-->
                                                <!--                        <mat-card-title>Shiba Inu</mat-card-title>-->
                                                <!--                        <mat-card-subtitle>Dog Breed</mat-card-subtitle>-->
                                                <!--                    </mat-card-header>-->
                                                <mat-card-content>
                                                    <ng-container *ngIf="incomingFormSettings.getInvitationCard() === responseResult.formControl[strings.getCntrlNum()];else contents">
                                                        <!--                                                    <ng-container *ngIf="shc">-->
                                                        <!--                                                        <img mat-card-image-->
                                                        <!--                                                             *ngIf="this.pdfUrl !== null && incomingFormSettings.getInvitationCard() === responseResult.formControl[strings.getCntrlNum()]"-->
                                                        <!--                                                             [src]="pdfUrl"-->
                                                        <!--                                                             alt="">-->
                                                        <!--                                                        <p [innerHTML]="responseResult.formControl.label | safeUrl"-->
                                                        <!--                                                           *ngIf="this.pdfUrl === null"-->
                                                        <!--                                                           [ngStyle]="{'position':'relative','left':-this.service.getWidth()+'px'}"></p>-->
                                                        <!--                                                        <ul ngClass="pull-right list-inline"-->
                                                        <!--                                                            [ngStyle]="{'position':'fixed','top':'50%','right':'50%','transform': 'translate(-50%, -50%)'}">-->
                                                        <!--                                                            <li *ngIf="this.pdfUrl === null">-->
                                                        <!--                                                                wait..-->
                                                        <!--                                                            </li>-->
                                                        <!--                                                            <li>-->
                                                        <!--                                                                <mat-spinner class=""-->
                                                        <!--                                                                             color="warn"-->
                                                        <!--                                                                             *ngIf="this.pdfUrl === null"-->
                                                        <!--                                                                             [diameter]="40"></mat-spinner>-->
                                                        <!--                                                            </li>-->
                                                        <!--                                                        </ul>-->
                                                        <!--                                                    </ng-container>-->
                                                    </ng-container>
                                                    <ng-template #contents>
                                                        <p [innerHTML]="responseResult.formControl.label.replaceAll('\\n', '<br>') | safeUrl"></p>
                                                    </ng-template>

                                                    <p *ngIf="this.service.parseFloat(responseResult.formControl[this.strings.getControlId()])===11">
                                                        <mat-calendar [selected]="responseResult.hasOwnProperty('date') ? responseResult.date : ''"
                                                                      (selectedChange)="incomingResponseResultSelected.setAttributes(responseResult);
                                                      onSelectDate($event)">
                                                        </mat-calendar>
                                                    </p>
                                                </mat-card-content>
                                                <mat-card-actions>
                                                    <center>
                                                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl[this.strings.getControlId()]) === 2;else card">
                                                            <span>
                                                                <a mat-fab  mat-button
                                                                   (click)="incomingResponseResultSelected.setAttributes(responseResult);imagefl.click()"
                                                                   class="defaultColor">
                                                                    <i class="material-icons">linked_camera</i>
                                                                </a>
                                                            </span>
                                                        </ng-container>
                                                        <ng-template #card>
                                                            <ng-container *ngIf="responseResult.formControl[this.strings.getCntrlNum()]===this.incomingFormSettings.getInvitationCard();else address">
                                                                <!--                                                                <span *ngIf="shc">-->
                                                                <!--                                                                    <button  mat-button-->
                                                                <!--                                                                             class="mdl-shadow&#45;&#45;2dp"-->
                                                                <!--                                                                             (click)="getCard(responseResult)"-->
                                                                <!--                                                                             [disabled]="false">-->
                                                                <!--                                                                        {{service.upperStartingCharacter(strings.getDownload(), true)}}-->
                                                                <!--                                                                    </button>-->
                                                                <!--                                                                </span>-->
                                                            </ng-container>
                                                        </ng-template>
                                                        <ng-template #address>
                                                            <ng-container *ngIf="this.service.parseFloat(responseResult.formControl[this.strings.getControlId()]) === 6;else actions">
                                                                <!--                                                                <span>-->
                                                                <!--                                                                    <a mat-mini-fab  mat-button-->
                                                                <!--                                                                       class="defaultColor">-->
                                                                <!--                                                                        <i class="material-icons"-->
                                                                <!--                                                                           [ngStyle]="{'font-size':'medium'}"-->
                                                                <!--                                                                           (click)="incomingResponseResultSelected.setAttributes(responseResult);openAgmmap()">-->
                                                                <!--                                                                            location_on</i>-->
                                                                <!--                                                                    </a>-->
                                                                <!--                                                                </span>-->
                                                            </ng-container>
                                                        </ng-template>
                                                        <ng-template #actions>
                                                            <ng-container *ngIf="responseResult.formControl.options.length !== 0">
                                                                <ng-container *ngIf="responseResult.formControl.control.id === 5;else checkbox">
                                                                     <span *ngFor="let option of responseResult.formControl.options"
                                                                           [ngStyle]="{'margin-right':'10px'}">
                                                                        <button  mat-button
                                                                                 class="mdl-shadow--2dp"
                                                                                 [ngStyle]="{'background-color':(responseResult.result.hasOwnProperty(option[this.strings.getCntrlNum()]) ? 'rgba(202, 193, 60, 0.2)' : '')}"
                                                                                 [disabled]="this.service.stringify(responseResult.result).includes(option[this.strings.getCntrlNum()])"
                                                                                 (click)="onOptionSelect(option,responseResult)">
                                                                            {{option.label}}
                                                                        </button>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-template #checkbox>
                                                                    <div class="demo-list-actionx mdl-listx">
                                                                        <table [ngStyle]="{'width':'100%'}">
                                                                            <tr *ngFor="let option of responseResult.formControl.options">
                                                                                <td [ngStyle]="{'width':'100%','font-weight': ((option.settings.hasOwnProperty(strings.getLabel()) &&
                                                                                    service.parseFloat(option.settings.label) === 1) ? 'bold': ''), 'font-size':  ((option.settings.hasOwnProperty(strings.getLabel()) &&
                                                                                    service.parseFloat(option.settings.label) === 1) ? '15px': '')}" [vAlign]="'top'">{{option.label}}</td>
                                                                                <td  [vAlign]="'top'"
                                                                                     *ngIf="!(option.settings.hasOwnProperty(strings.getLabel()) &&
                                                                                    service.parseFloat(option.settings.label) === 1)">
                                                                                    <mat-checkbox
                                                                                        #checked
                                                                                        [ngModel]='responseResult.data.includes(option.cntrl_num)'
                                                                                        class=""
                                                                                        (change)="onClick(responseResult,checked,option)"
                                                                                        [value]="option">
                                                                                    </mat-checkbox>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                            <!--                                                                       <span-->
                                                                            <!--                                                                                    [title]="'Please add missing one'"-->
                                                                            <!--                                                                                    (click)="addMissing(responseResult)"-->
                                                                            <!--                                                                                    [ngStyle]="{'margin-right':'10px'}">-->
                                                                            <!--&lt;!&ndash;                                                                                    <a mat-mini-fab class="defaultColor2">&ndash;&gt;-->
                                                                            <!--&lt;!&ndash;                                                                                        <i class="material-icons">add</i>&ndash;&gt;-->
                                                                            <!--&lt;!&ndash;                                                                                    </a>&ndash;&gt;-->
                                                                            <!--                                                                                    <button  mat-button-->
                                                                            <!--                                                                                             class="mdl-shadow&#45;&#45;2dp"-->
                                                                            <!--                                                                                             [disabled]="false">-->
                                                                            <!--                                                                                        Please add missing one-->
                                                                            <!--&lt;!&ndash;                                                                                        <i class="fa fa-plus-circle"></i>&ndash;&gt;-->
                                                                            <!--                                                                                    </button>-->
                                                                            <!--                                                                          </span>-->
                                                                            <span
                                                                                    [title]="'Next'"
                                                                                    [ngStyle]="{'margin-right':'10px'}">
<!--                                                                                    <a mat-mini-fab class="defaultColor2">-->
                                                                                <!--&lt;!&ndash;                                                                                        <i class="material-icons">expand_more</i>&ndash;&gt;-->
                                                                                <!--                                                                                        <i class="material-icons">chevron_right</i>-->
                                                                                <!--                                                                                    </a>-->
                                                                                    <button  mat-button
                                                                                             class="mdl-shadow--2dp"
                                                                                             (click)="next(responseResult)"
                                                                                             [disabled]="false">
                                                                                        {{strings.getNext()}}
                                                                                        <!--                                                                                        <i class="fa fa-long-arrow-right"></i>-->
                                                                                        <i class="material-icons">keyboard_arrow_right</i>
                                                                                    </button>
                                                                              </span>
                                                                        </div>
                                                                        <!--                                                                        <div class="mdl-list__item" *ngFor="let option of responseResult.formControl.options">-->
                                                                        <!--                                                                            <span class="mdl-list__item-primary-content">-->
                                                                        <!--&lt;!&ndash;                                                                          <i class="material-icons mdl-list__item-avatar">person</i>&ndash;&gt;-->
                                                                        <!--                                                                              <span class="" [ngStyle]="{'padding-right':'20px'}">{{option.label}}</span>-->
                                                                        <!--                                                                            </span>-->
                                                                        <!--                                                                            <a class="mdl-list__item-secondary-action"-->
                                                                        <!--                                                                               *ngIf="!(option.settings.hasOwnProperty(strings.getLabel()) &&-->
                                                                        <!--                                                                                service.parseFloat(option.settings.label) === 1)">-->
                                                                        <!--                                                                                <mat-checkbox-->
                                                                        <!--                                                                                    #checked-->
                                                                        <!--                                                                                    [ngModel]='responseResult.data.includes(option.cntrl_num)'-->
                                                                        <!--                                                                                    class=""-->
                                                                        <!--                                                                                    (change)="onClick(responseResult,checked,option)"-->
                                                                        <!--                                                                                    [value]="option">-->
                                                                        <!--                                                                                </mat-checkbox>-->
                                                                        <!--                                                                            </a>-->
                                                                        <!--                                                                        </div>-->
                                                                    </div>
                                                                </ng-template>
                                                                <!--                                                                <span >-->
                                                                <!--                                                                    <button  mat-button-->
                                                                <!--                                                                             class="mdl-shadow&#45;&#45;2dp"-->
                                                                <!--                                                                             [disabled]="false"-->
                                                                <!--                                                                             (click)="getCard(responseResult)"-->
                                                                <!--                                                                             *ngIf="responseResult.formControl[this.strings.getCntrlNum()].includes(this.incomingFormSettings.getInvitationCard())">-->
                                                                <!--                                                                        {{this.service.upperStartingCharacter(strings.getDownload(),true)}}-->
                                                                <!--                                                                    </button>-->
                                                                <!--                                                                </span>-->
                                                            </ng-container>
                                                        </ng-template>
                                                    </center>
                                                </mat-card-actions>
                                            </mat-card>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                         [ngStyle]="{'margin-top':'50px'}"
                                         *ngIf="!service.isNull(responseResult.data)">
                                        <mat-card class="example-card pull-right" [ngStyle]="{'max-width':'50%','background':'rgb(217, 253, 211)'}">
                                            <ng-container *ngIf="service.parseFloat(responseResult.formControl[strings.getControlId()])!==3; else contents">
                                                <mat-card-content>
                                                    <p [innerHTML]="responseResult.data"></p>
                                                    <ng-container *ngIf="service.parseFloat(responseResult.formControl[strings.getControlId()]) === 2">
                                                        <img  src="{{responseResult.data}}"
                                                              ngClass="mdl-shadow--3dp"
                                                              [ngStyle]="{'width':'100%','height':'auto'}"/>
                                                    </ng-container>
                                                </mat-card-content>
                                            </ng-container>
                                            <ng-template  #contents>
                                                <ng-container *ngIf="service.parseFloat(responseResult.formControl[strings.getControlId()]) === 3">
                                                    <table>
                                                        <ng-container *ngFor="let option of responseResult.formControl.options">
                                                            <tr *ngIf="responseResult.data.includes(option.cntrl_num)">
                                                                <td>{{option.label}}</td>
                                                                <td>
                                                                    <mat-checkbox
                                                                            [ngModel]='responseResult.data.includes(option.cntrl_num)'
                                                                            class=""
                                                                            [value]="option">
                                                                    </mat-checkbox>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </table>
                                                </ng-container>
                                            </ng-template>
                                        </mat-card>
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom">
                                <div [ngStyle]="{'border-radius': '25px','background':'#fff','margin-bottom':'5px'}"
                                     class="top_search mdl-shadow--2dp">
                                    <table [ngStyle]="{'width':'100%'}">
                                        <td [ngStyle]="{'width':'100%'}">
                                            <input  [ngStyle]="{'color':'#000','text-align':'center','width':'100%'}"
                                                    autocomplete="off"
                                                    #suggestionId
                                                    (keyup.enter)="onkeyupenter(suggestionId.value)"
                                                    (input)="onInputTextChangeChatbot(suggestionId.value)"
                                                    ngClass="searchbar"
                                                    [disabled]="false"
                                                    [formControl] = "suggestionControl"
                                                    placeholder="">
                                            <!--                                                [matAutocomplete] = "suggestion"-->
                                            <!--                                                <mat-autocomplete #suggestion = "matAutocomplete"-->
                                            <!--                                                                  (optionSelected)="onSuggestionSelectionChanged($event)"-->
                                            <!--                                                                  [displayWith]="displayFn">-->
                                            <!--                                                    <mat-option *ngFor="let suggestion of filteredSuggestionsOptions | async; let suggestionOf = index"-->
                                            <!--                                                                [value]= "suggestion">-->
                                            <!--                                                        {{suggestion.hasOwnProperty(this.strings.getLabel()) ? suggestion.label : suggestion.description}}-->
                                            <!--                                                    </mat-option>-->
                                            <!--                                                </mat-autocomplete>-->
                                        </td>
                                        <td class="cursor">
                                            <mat-icon matSuffix (click)="onkeyupenter(suggestionId.value)">send</mat-icon>
                                        </td>
                                        <!--                                            <td>-->
                                        <!--                                                <mat-icon matSuffix>qr_code</mat-icon>-->
                                        <!--                                            </td>-->
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <!--        <img [ngStyle]="{'width': '100%', 'height': service.getHeight()+'px', 'display':'block', 'object-fit': 'cover'}"-->
                    <!--             src="https://patten.co.tz/server/uza/public/images/clients/65f97aedd949825"/>-->
                    <!--        <div [ngStyle]="{'position':'absolute','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px'}">-->
                    <!--            <div class=""-->
                    <!--                 [ngStyle]="{'padding-top':'0px','padding-bottom':'0px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%', 'background-color':'transparent'}">-->
                    <!--                <ng-container *ngFor="let responseResult of outgoingResponseResults">-->
                    <!--                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
                    <!--                        <mat-card class="example-card pull-right" [ngStyle]="{'max-width':'50%'}">-->
                    <!--                            <img mat-card-image src="assets/images/avatar.jpg" alt="Photo of a Shiba Inu">-->
                    <!--                            &lt;!&ndash;                    <mat-card-header>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <div mat-card-avatar class="example-header-image"></div>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <mat-card-title>Shiba Inu</mat-card-title>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                        <mat-card-subtitle>Dog Breed</mat-card-subtitle>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                    </mat-card-header>&ndash;&gt;-->
                    <!--                            <mat-card-content>-->
                    <!--                                <p [innerHTML]="responseResult.formControl.label">-->
                    <!--                                </p>-->
                    <!--                            </mat-card-content>-->
                    <!--                            <mat-card-actions *ngIf="responseResult.formControl.options.length !== 0">-->
                    <!--                                <button  mat-button-->
                    <!--                                         class="mdl-shadow&#45;&#45;2dp"-->
                    <!--                                         [ngStyle]="{'width':'100%','background-color':(responseResult.result.hasOwnProperty(option[this.strings.getCntrlNum()]) ? 'rgba(202, 193, 60, 0.2)' : '')}"-->
                    <!--                                         [disabled]="false"-->
                    <!--                                         *ngFor="let option of responseResult.formControl.options"-->
                    <!--                                         (click)="onOptionSelect(option,responseResult)">-->
                    <!--                                    {{option.label}}-->
                    <!--                                </button>-->
                    <!--                            </mat-card-actions>-->
                    <!--                        </mat-card>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'margin-top':'50px'}"-->
                    <!--                    *ngIf="!service.isNull(responseResult.data)">-->
                    <!--                        <mat-card class="example-card pull-left" [ngStyle]="{'max-width':'50%'}">-->
                    <!--                            <mat-card-content *ngIf="responseResult.formControl.options.length === 0">-->
                    <!--                                <p>-->
                    <!--                                    The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.-->
                    <!--                                    A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally-->
                    <!--                                    bred for hunting.-->
                    <!--                                </p>-->
                    <!--                            </mat-card-content>-->
                    <!--                            <mat-card-actions *ngIf="responseResult.formControl.options.length !== 0">-->
                    <!--                                <ng-container-->
                    <!--                                        *ngFor="let option of responseResult.formControl.options">-->
                    <!--                                    <button  mat-button-->
                    <!--                                             class=""-->
                    <!--                                             [disabled]="false"-->
                    <!--                                             *ngIf="responseResult.result.hasOwnProperty(option[this.strings.getCntrlNum()])"-->
                    <!--                                             (click)="onOptionSelect(option,responseResult)">-->
                    <!--                                        {{option.label}}-->
                    <!--                                    </button>-->
                    <!--                                </ng-container>-->
                    <!--                            </mat-card-actions>-->
                    <!--                        </mat-card>-->
                    <!--                    </div>-->
                    <!--                </ng-container>-->
                    <!--            </div>-->
                    <!--        </div>-->
                </div>
            </mat-drawer-content>
        </mat-drawer-container>

    </div>
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
</cdk-virtual-scroll-viewport>


<input type="file"
       (change)="onImgflUploaded($event)"
       [ngStyle]="{'display':'none'}"
       accept="image/*"
       #imagefl>


<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector" >
        <ng-container *ngIf="flagforContextmenu === 0">
            <button mat-menu-item>Reply</button>
        </ng-container>
    </ng-template>
</mat-menu>

