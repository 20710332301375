<mat-dialog-content #matDialogContent>
    <ng-container *ngIf="this.data.hasOwnProperty(strings.getImages())">
            <carousel [cellsToScroll]="1"
                      [cellsToShow]="1"
                      [height]="this.data.height"
                      [width]="this.data.width"
                      [autoplay]="true"
                      [lightDOM]="false"
                      [arrows]="true"
                      [dots]="false"
                      [loop]="true"
                      #myCarousel>
                <ng-container *ngFor="let image of this.images;let incrementalResponseResult=index">
                    <div class="carousel-cell" #carouselCell>
                        <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                             src="{{image.data}}"
                             #img />
                    </div>
                </ng-container>
            </carousel>
    </ng-container>
</mat-dialog-content>
