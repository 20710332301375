import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {Parameters} from '../parameters';
import {FormService} from './form.service';
import {DialogFormsComponent} from './dialog-forms.component';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatMenuTrigger} from '@angular/material/menu';
import {CampaignService} from "../campaigns/campaign.service";

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any = {data: null};
  parameters: any;
  params: any;
  selected: any;
  startingAt: any;
  endingAt: any;
  incomingReport: any;
  formControl = new FormControl();
  filteredFormsOptions: any;
  initColumns: any = [];
  displayedColumns: string[] = [];
  selectedSuggestionBox: any;
  keys: any = 0;
  selectedResponse: any;
  searchCntrl: any;
  showFiller: any = false;
  mode: any = new FormControl('over');
  shouldRun: any = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
  dataSource: any = [];
  constructor(private service: AppService,
              private campaignService: CampaignService,
              private  formService: FormService,
              public dialog: MatDialog) {
    this.app = this.service.app;
    this.app.form.modules  = [{path: '/app/forms', title: 'Forms'}];
    this.app.form.subtitle = 'surveys and polls to collect customer feedback, measure employee satisfaction, and organize team events';
    this.app.form.module   = 'forms';
    this.selected = null;
    this.formService.setData(this.app.data.forms);
    this.startingAt = new Date();
    this.endingAt   = new Date();
    this.incomingReport = new Parameters();
    this.incomingReport.setAttributes(this.formService.report.attributes);
    this.contextMenu = MatMenuTrigger;
    this.formService.campaignId = campaignService.campaignSelectedId;
    this.formService.categoryId = 5;
    this.service.status = -1;
    this.service.modules = [{title: 'Forms', path: ''}];
    this.service.module = 'projects';
  }


  ngOnInit(): void {
    this.formService.setSelected({});
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
  }

  openDialog() {
    this.formService.openDialog({flag: 3});
  }

}
