<div *ngIf="this.service.isNull(this.pdfUrl)">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0"></div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
             [ngStyle]="{'font-size':'16px','padding-top':'10px'}">
            <div class="textBlueColor" [innerHTML]="incomingForm.getDescription() | safeUrl"></div><br><br>
            <div>
              <table [ngStyle]="{'width':'100%'}">
                <tr *ngFor="let question  of questions">
                    <ng-container *ngIf="!(question[strings.getCntrlNum()] === incomingFormSettings.getPaymentMethod() ||
                                           question[strings.getCntrlNum()] === incomingFormSettings.getIsPayed() ||
                                           question[strings.getCntrlNum()] === incomingFormSettings.getInvitationCard())">
                        <ng-container *ngIf="question.hasOwnProperty(this.strings.getShow()) && this.service.parseFloat(question.show) === 1">
                            <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 12; else choice">
                                <ng-container *ngIf="!(question[strings.getCntrlNum()] === incomingFormSettings.getInvitationCard())">
                                    <td>
                                        <div class="textBlueColor" [innerHTML]="question[strings.getLabel()] | safeUrl"></div><br><br>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <ng-template #choice>
                                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 5; else checkbox">
                                    <td [id]="question[this.strings.getIndex()]">
                                        <span class="textBlueColor" [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                                        <mat-radio-group
                                                aria-labelledby="example-radio-group-label"
                                                class="example-radio-group">
                                            <mat-radio-button
                                                    class="example-radio-buttonn"
                                                    #checked
                                                    [checked]="isChecked(question, checked, option)"
                                                    (change)="onChecked(question, option, checked)"
                                                    *ngFor="let option of question[strings.getOptions()]"
                                                    [value]="option">
                                                <a class="" [innerHTML]="option[strings.getLabel()]"></a>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #checkbox>
                                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 3; else address">
                                    <td [id]="question[this.strings.getIndex()]">
                                        <span class="textBlueColor" [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                                        <section class="example-section">
                                            <p *ngFor="let option of question[strings.getOptions()]">
                                                <mat-checkbox class="example-margin"
                                                              #checked
                                                              (change)="onChecked(question, option, checked)">
                                                    {{option[strings.getLabel()]}}
                                                </mat-checkbox>
                                            </p>
                                        </section>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #address>
                                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 6; else  contents">
                                    <td [id]="question[this.strings.getIndex()]">
                                        <span class="textBlueColor" [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? '* ' : '') }}</span><br>
                                        <mat-form-field  class="example-full-width" appearance="outline">
                                            <input type="text"
                                                   class="sawasdee26 text-center"
                                                   matInput
                                                   #addressElement
                                                   autocomplete="off"
                                                   placeholder=""
                                                   (input)="onInputTextChange(question, $event)"
                                                   [ngStyle]="{'text-align': 'center'}"
                                                   matGoogleMapsAutocomplete>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #card>
                                <ng-container *ngIf="this.incomingFormSettings.hasAttribute(this.strings.getInvitationCard()) && this.incomingFormSettings.getInvitationCard() === question[this.strings.getCntrlNum()]; else contents">
                                    <!--                            <div [innerHTML]="question[this.strings.getLabel()] | safeUrl"></div>-->
                                </ng-container>
                            </ng-template>
                            <ng-template #contents>
                                <td [id]="question[this.strings.getIndex()]">
                                    <span class="textBlueColor">{{question[strings.getLabel()]}} </span><span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                                    <mat-form-field  class="example-full-width" appearance="outline">
                                        <input type="text"
                                               class="text-center"
                                               aria-label = "Number"
                                               [ngStyle]="{'text-align': 'center'}"
                                               (input)="onInputTextChange(question, $event)"
                                               [(ngModel)]="question.data"
                                               [placeholder]="''"
                                               matInput>
                                    </mat-form-field>
                                </td>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <td>
                        <div style="transform:scale(1.16);transform-origin:0;-webkit-transform:scale(1.16);transform:scale(1.16);-webkit-transform-origin:0 0;transform-origin:0 0;">
                            <ngx-recaptcha2 #recaptchaId
                                            [siteKey]="siteKey"
                                            (reset)="handleReset()"
                                            (expire)="handleExpire()"
                                            (load)="handleLoad()"
                                            (success)="handleSuccess($event)"
                                            [useGlobalDomain]="false">
                            </ngx-recaptcha2>
                        </div><br><br>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <a (click)="ngSubmit({})"
                               class="btn btn-lg"
                               [ngStyle]="{'background-color':this.incomingFormSettings.getBackgroundColor(),'color':'#fff'}" >
                                Submit
                            </a>
                        </div>
                    </td>
                </tr>
            </table>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0"></div>
    </div>
</div>


<div *ngIf="!this.service.isNull(this.pdfUrl)"
     [ngStyle]="{'position':'absolute','top':'0%','background-color':this.incomingFormSettings.getBackgroundColor(), 'height':this.service.getHeight()+'px','width':'100%','margin-top':'0px','margin-bottom':'0px'}">
    <div class="vertical-center"
         [ngStyle]="{'padding-left':'0px','padding-top':'0px','padding-bottom':'0px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':this.incomingFormSettings.getBackgroundColor()}">
        <center>
            <mat-spinner class=""
                         color="warn"
                         *ngIf="this.service.isNull(this.canvas)"
                         [diameter]="40"></mat-spinner>

        </center>
        <ng-container *ngIf="!this.service.isNull(canvas)">
            <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0"></div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <md-card [ngStyle]="{'width':'auto'}">
                    <img [src]="this.canvas"
                         mat-card-image
                         class="mdl-shadow--3dp"
                         [ngStyle]="{'width':'100%','height':'100%'}"/>
                    <mat-card-content>
                        <div [innerHTML]="incomingInvitationCard.getSettings()[strings.getAlias()]"></div>
                    </mat-card-content>
                </md-card>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-0 col-xs-0"></div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <center>
                    <a class="btn btn-lg btn-default" (click)="moveTo()">
                         <table>
                             <td valign="top"><mat-icon>chevron_left</mat-icon></td>
                             <td valign="top">Home</td>
                         </table>

                    </a>
                </center>
            </div>
        </ng-container>
    </div>
</div>

<div [innerHTML]="incomingInvitationCard.getLabel() | safeUrl"
     [ngStyle]="{'position':'fixed','top':'0px','left': -this.service.getWidth()+'px','z-index':'99999999999'}"
     *ngIf="!this.service.isNull(this.pdfUrl) && this.service.isNull(this.canvas)" ></div>